<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card elevation="0" v-if="info.succeeded" class="grey lighten-4">
          <v-carousel
            height="25vh"
            hide-delimiter-background
            hide-delimiters
            v-model="currentImage"
          >
            <v-carousel-item v-for="n in items.length" :key="n">
              <v-img
                lazy-src="https://cdn.el-turk.com/common/lazy-load.png"
                :src="items[n - 1].path"
                class="rounded-xl"
                max-width="100%"
                height="25vh"
              >
              </v-img>
            </v-carousel-item>
          </v-carousel>
          <v-row class="mt-1 ml-1">
            <v-col cols="3" v-for="n in items.length" :key="n" class="px-1">
              <v-img
                lazy-src="https://cdn.el-turk.com/common/lazy-load.png"
                :src="items[n - 1].path"
                class="rounded-lg text-center"
                @click="open(n - 1)"
                max-width="100%"
                height="5vh"
                style="cursor: pointer"
              >
              </v-img>
            </v-col>
          </v-row>
        </v-card>
        <v-card elevation="0" width="100%" v-else class="grey lighten-4">
          <v-skeleton-loader
            height="40vh"
            width="100%"
            type="card"
          ></v-skeleton-loader>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "ResidentialComplexImagesComponent",
  computed: {
    ...mapGetters({
      getInfo: "house/GET_INFO",
    }),
    info: {
      get: function () {
        return this.getInfo;
      },
    },
  },
  data: () => ({
    items: null,
    currentImage: null,
  }),
  methods: {
    open(n) {
      this.currentImage = n;
    },
  },
  watch: {
    info: function () {
      this.items = this.info.data.images;
    },
  },
  created() {
    this.items = this.info.data.images;
  },
};
</script>