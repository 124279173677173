<template>
  <v-container>
    <v-card
      elevation="0"
      class="rounded-lg px-1 py-1"
      height="80vh"
      style="border: 1px solid #4caf50"
      id="archiveTable"
    >
      <v-row class="d-flex align-center">
        <v-col cols="12">
          <v-data-table
            :headers="headers[selectedLanguage.code]"
            :items="list.data"
            :items-per-page="20"
            class="elevation-0"
            hide-default-footer
            :loading="loading"
            height="60vh"
            dense
          >
            <template slot="progress">
              <v-progress-linear
                color="green"
                indeterminate
              ></v-progress-linear>
            </template>
            <template v-slot:[`item.description`]="{ item }">
              <span class="text-caption font-weight-medium">{{
                item.description
              }}</span>
            </template>

            <template v-slot:[`item.link`]="{ item }">
              <v-btn text @click="openLink(item.link)">
                <v-icon small color="purple">$customDocumentPdf2</v-icon>
              </v-btn>
            </template>
            <template v-slot:[`item.createdDate`]="{ item }">
              <span class="text-caption font-weight-medium">{{
                new Date(item.createdDate).toLocaleDateString()
              }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                small
                color="red"
                class="mr-2"
                @click="download(item.link)"
              >
                $customDownload
              </v-icon>
              <v-icon small color="red" @click="deleteItem(item)">
                $customTrash2
              </v-icon>
            </template>

            <template v-slot:top>
              <v-toolbar flat>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="500px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="purple"
                      dark
                      class="mb-2 text-capitalize rounded-lg px-6"
                      v-bind="attrs"
                      v-on="on"
                      small
                      elevation="0"
                      style="font-weight: 700"
                    >
                      {{ stringValues.add[selectedLanguage.code] }}
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="text-subtitle-2">{{
                        editedIndex === -1
                          ? stringValues.add[selectedLanguage.code]
                          : stringValues.change[selectedLanguage.code]
                      }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-textarea
                              v-model="editedItem.name"
                              :label="stringValues.name1[selectedLanguage.code]"
                              color="purple"
                            ></v-textarea>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-file-input
                              v-if="editedIndex === -1"
                              v-model="documentFile"
                              accept="image/png, image/jpeg, image/bmp, image/jpg, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                              placeholder="Выберите файлы"
                              prepend-icon="mdi-file-arrow-up-down"
                              :label="
                                stringValues.addFile[selectedLanguage.code]
                              "
                              color="purple"
                            ></v-file-input>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="purple" text small @click="close">
                        {{ stringValues.cancel[selectedLanguage.code] }}
                      </v-btn>
                      <v-btn color="purple" text small @click="save">
                        {{ stringValues.apply[selectedLanguage.code] }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="text-subtitle-2">{{
                      stringValues.deleteSure[selectedLanguage.code]
                    }}</v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="purple" text small @click="closeDelete">
                        {{ stringValues.cancel[selectedLanguage.code] }}</v-btn
                      >
                      <v-btn
                        color="purple"
                        text
                        small
                        @click="deleteItemConfirm"
                        >{{ stringValues.apply[selectedLanguage.code] }}</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
          </v-data-table>
          <v-pagination
            circle
            v-model="list.pageNumber"
            :length="list.totalPages"
            color="purple"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "ArchiveListComponent",
  computed: {
    ...mapGetters({
      getList: "house/GET_ARCHIVES",
      getActionStatus: "house/GET_ACTION_STATUS",
      getInfo: "house/GET_INFO",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
      getHomeInfo: "house/GET_HOME_INFO",
    }),
    info: {
      get: function () {
        return this.getInfo;
      },
    },
    list: {
      get: function () {
        return this.getList;
      },
      set: function (newVal) {
        this.setList(newVal);
      },
    },
    actionStatus: {
      get: function () {
        return this.getActionStatus;
      },
    },
    formTitle() {
      return this.editedIndex === -1 ? "Добавить" : "Изменить";
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
    homeInfo: {
      get: function () {
        return this.getHomeInfo;
      },
    },
  },
  data: () => ({
    loading: false,
    headers: {
      ru: [
        {
          text: "Название",
          value: "name",
          align: "left",
          sortable: false,
          width: "40%",
          class: "black--text",
        },
        {
          text: "Дата",
          value: "createdDate",
          align: "center",
          sortable: false,
          width: "10%",
          class: "black--text",
        },
        {
          text: "Ссылка",
          value: "link",
          align: "center",
          sortable: false,
          width: "40%",
          class: "black--text",
        },
        {
          text: "",
          value: "actions",
          align: "center",
          sortable: false,
          width: "10%",
          class: "black--text",
        },
      ],
      en: [
        {
          text: "Title",
          value: "name",
          align: "left",
          sortable: false,
          width: "40%",
          class: "black--text",
        },
        {
          text: "Date",
          value: "createdDate",
          align: "center",
          sortable: false,
          width: "10%",
          class: "black--text",
        },
        {
          text: "Link",
          value: "link",
          align: "center",
          sortable: false,
          width: "40%",
          class: "black--text",
        },
        {
          text: "",
          value: "actions",
          align: "center",
          sortable: false,
          width: "10%",
          class: "black--text",
        },
      ],
      tr: [
        {
          text: "Başlık",
          value: "name",
          align: "left",
          sortable: false,
          width: "40%",
          class: "black--text",
        },
        {
          text: "Tarih",
          value: "createdDate",
          align: "center",
          sortable: false,
          width: "10%",
          class: "black--text",
        },
        {
          text: "Referans",
          value: "link",
          align: "center",
          sortable: false,
          width: "40%",
          class: "black--text",
        },

        {
          text: "",
          value: "actions",
          align: "center",
          sortable: false,
          width: "10%",
          class: "black--text",
        },
      ],
    },
    dialog: false,
    dialogDelete: false,
    editedItem: {
      id: 0,
      name: "",
    },
    defaultItem: {
      id: 0,
      name: "",
    },
    editedIndex: -1,
    documentFile: null,
  }),
  methods: {
    ...mapActions({
      getListFromApi: "house/GET_ARCHIVES_LIST_BY_RC_ID_FROM_API",
      addFromApi: "house/ADD_ARCHIVE_FROM_API",
      deleteFromApi: "house/DELETE_ARCHIVE_FROM_API",
      commitMenuBtnWidth: "common/COMMIT_MENU_BTN_WIDTH",
      getHomeInfoByIdFromApi: "house/GET_HOME_INFO_BY_ID_FROM_API",
    }),
    ...mapMutations({
      setSnackBar: "common/SET_SNACKBAR",
      setList: "house/SET_ARCHIVES",
    }),
    async getItemsFromApi(pageNumber, pageSize, rcId) {
      this.loading = true;
      await this.getListFromApi({
        pageNumber: pageNumber,
        pageSize: pageSize,
        residentialComplexId: rcId,
      });
      this.loading = false;
    },
    deleteItem(item) {
      this.editedIndex = this.list.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      await this.deleteFromApi({
        archiveId: this.editedItem.id,
      });
      if (this.actionStatus.succeeded) {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "green",
          text: this.stringValues.ok[this.selectedLanguage.code],
        });
        this.list.data.splice(this.editedIndex, 1);
      } else {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "purple",
          text: this.stringValues.error[this.selectedLanguage.code],
        });
      }
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (this.documentFile != null && this.documentFile != undefined) {
        var base64 = await this.documentToBase64();
        base64 = base64.substring(base64.indexOf(",") + 1);

        var extensions = /[.]/.exec(this.documentFile.name)
          ? /[^.]+$/.exec(this.documentFile.name)
          : undefined;
        if (
          extensions != undefined &&
          extensions != null &&
          extensions.length > 0
        ) {
          await this.addFromApi({
            name: this.editedItem.name,
            residentialComplexId: this.info.data.id,
            attachment: {
              name: this.uidv4(),
              value: base64,
              extension: "." + extensions[0],
            },
          });
          if (this.actionStatus.succeeded) {
            this.editedItem.createdDate = new Date();
            this.list.data.unshift(this.editedItem);
            this.setSnackBar({
              show: true,
              timeout: 3000,
              color: "green",
              text: this.stringValues.ok[this.selectedLanguage.code],
            });
          } else {
            this.setSnackBar({
              show: true,
              timeout: 3000,
              color: "purple",
              text: this.stringValues.error[this.selectedLanguage.code],
            });
          }
          this.close();
        }
      }
    },
    uidv4() {
      return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
    },
    initMenuBtnWidth() {
      var btnOffsets = document
        .getElementById("menuItem_" + 8)
        .getBoundingClientRect();
      var tableOffsets = document
        .getElementById("archiveTable")
        .getBoundingClientRect();
      let width = tableOffsets.left - btnOffsets.right + btnOffsets.width + 2;
      this.commitMenuBtnWidth(width);
    },
    async documentToBase64() {
      const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });
      };

      return await getBase64(this.documentFile);
    },
    openLink(link) {
      window.open(link, "_blank");
    },
    download(url) {
      this.downloadFile(url, "faw");
    },
    downloadFile(url, fileName) {
      const a = document.createElement("a");
      a.href = url;
      a.setAttribute("download", fileName);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
  },
  watch: {
    "list.pageNumber": function () {
      this.getItemsFromApi(this.list.pageNumber, 20, this.info.data.id);
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    info: function () {
      this.initMenuBtnWidth();
      this.getItemsFromApi(1, 20, this.info.data.id);
      this.getHomeInfoByIdFromApi({ residentialComplexId: this.info.data.id });
    },
  },
  async mounted() {
    this.initMenuBtnWidth();
    window.addEventListener("resize", this.initMenuBtnWidth);
    if (this.info.succeeded) {
      await this.getItemsFromApi(1, 20, this.info.data.id);
      await this.getHomeInfoByIdFromApi({
        residentialComplexId: this.info.data.id,
      });
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.initMenuBtnWidth);
  },
};
</script>
