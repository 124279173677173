<template>
  <v-container>
    <v-card
      elevation="0"
      class="rounded-lg px-1 py-1"
      height="80vh"
      style="border: 1px solid #4caf50"
      id="documentsTable"
    >
      <v-row class="justify-center" no-gutters>
        <v-col cols="6">
          <v-card elevation="0" class="mx-auto">
            <v-row no-gutters>
              <v-col cols="12" class="text-left">
                <span
                  class="purple--text text-subtitle-1"
                  style="font-weight: 600"
                  >{{ stringValues.complexInfo[selectedLanguage.code] }}</span
                >
              </v-col>
            </v-row>
            <v-row no-gutters class="my-2">
              <v-col cols="6" class="text-left">
                <h4 class="font-weight-medium text-caption grey--text">
                  {{ stringValues.managementCompany[selectedLanguage.code] }}:
                </h4>
              </v-col>
              <v-col cols="6" class="text-right">
                <h4 class="black--text text-caption" style="font-weight: 600">
                  {{
                    homeInfo.succeeded ? homeInfo.data.company : "Нет данных!"
                  }}
                </h4>
              </v-col>
            </v-row>
            <v-row no-gutters class="my-2">
              <v-col cols="6" class="text-left">
                <h4 class="font-weight-medium text-caption grey--text">
                  {{ stringValues.periodOfOffice[selectedLanguage.code] }}:
                </h4>
              </v-col>
              <v-col cols="6" class="text-right">
                <h4 class="black--text text-caption" style="font-weight: 600">
                  {{
                    homeInfo.succeeded ? homeInfo.data.period : "Нет данных!"
                  }}
                </h4>
              </v-col>
            </v-row>
            <v-row no-gutters class="my-2">
              <v-col cols="6" class="text-left">
                <h4 class="font-weight-medium text-caption grey--text">
                  {{ stringValues.manager[selectedLanguage.code] }}:
                </h4>
              </v-col>
              <v-col cols="6" class="text-right">
                <h4 class="black--text text-caption" style="font-weight: 600">
                  {{
                    homeInfo.succeeded ? homeInfo.data.manager : "Нет данных!"
                  }}
                </h4>
              </v-col>
            </v-row>
            <v-row no-gutters class="my-2">
              <v-col cols="6" class="text-left">
                <h4 class="font-weight-medium text-caption grey--text">
                  {{ stringValues.telephone[selectedLanguage.code] }}:
                </h4>
              </v-col>
              <v-col cols="6" class="text-right">
                <h4 class="black--text text-caption" style="font-weight: 600">
                  {{
                    homeInfo.succeeded
                      ? homeInfo.data.telephoneNumber
                      : "Нет данных!"
                  }}
                </h4>
              </v-col>
            </v-row>
            <v-row no-gutters class="my-2">
              <v-col cols="6" class="text-left">
                <h4 class="font-weight-medium text-caption grey--text">
                  E-Mail:
                </h4>
              </v-col>
              <v-col cols="6" class="text-right">
                <h4 class="black--text text-caption" style="font-weight: 600">
                  {{ homeInfo.succeeded ? homeInfo.data.email : "Нет данных!" }}
                </h4>
              </v-col>
            </v-row>
            <v-row no-gutters class="my-2">
              <v-col cols="6" class="text-left">
                <h4 class="font-weight-medium text-caption grey--text">
                  {{ stringValues.address[selectedLanguage.code] }}:
                </h4>
              </v-col>
              <v-col cols="6" class="text-right">
                <h4 class="black--text text-caption" style="font-weight: 600">
                  {{
                    homeInfo.succeeded ? homeInfo.data.address : "Нет данных!"
                  }}
                </h4>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-divider />
      <v-row class="d-flex align-center">
        <v-col cols="12">
          <v-data-table
            :headers="headers[selectedLanguage.code]"
            :items="list.data"
            :items-per-page="20"
            class="elevation-0"
            hide-default-footer
            :loading="loading"
            height="30vh"
            dense
          >
            <template slot="progress">
              <v-progress-linear
                color="green"
                indeterminate
              ></v-progress-linear>
            </template>
            <template v-slot:[`item.description`]="{ item }">
              <span class="text-caption font-weight-medium">{{
                item.description
              }}</span>
            </template>

            <template v-slot:[`item.link`]="{ item }">
              <a
                class="text-caption font-weight-normal black--text"
                :href="item.link"
                target="_blank"
                >{{ item.link }}</a
              >
            </template>
            <template v-slot:[`item.createdDate`]="{ item }">
              <span class="text-caption font-weight-medium">{{
                new Date(item.createdDate).toLocaleDateString()
              }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="editItem(item)">
                $customPencil
              </v-icon>
              <v-icon small color="red" @click="deleteItem(item)">
                $customTrash
              </v-icon>
            </template>

            <template v-slot:top>
              <v-toolbar flat>
                <v-spacer />
                <v-card-title>
                  <span
                    class="purple--text text-subtitle-1"
                    style="font-weight: 600"
                    >{{
                      stringValues.complexDocuments[selectedLanguage.code]
                    }}</span
                  >
                </v-card-title>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="500px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="purple"
                      dark
                      class="mb-2 text-capitalize rounded-lg px-6"
                      v-bind="attrs"
                      v-on="on"
                      small
                      elevation="0"
                      style="font-weight: 700"
                    >
                      {{ stringValues.add[selectedLanguage.code] }}
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="text-subtitle-2">{{
                        editedIndex === -1
                          ? stringValues.add[selectedLanguage.code]
                          : stringValues.change[selectedLanguage.code]
                      }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <!-- <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="editedItem.link"
                              label="Ссылка"
                              color="purple"
                            ></v-text-field>
                          </v-col>
                        </v-row> -->
                        <v-row>
                          <v-col cols="12">
                            <v-textarea
                              v-model="editedItem.description"
                              :label="
                                stringValues.description[selectedLanguage.code]
                              "
                              color="purple"
                            ></v-textarea>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-file-input
                              v-if="editedIndex === -1"
                              v-model="documentFile"
                              accept="image/png, image/jpeg, image/bmp, image/jpg, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                              placeholder="Выберите файлы"
                              prepend-icon="mdi-file-arrow-up-down"
                              :label="
                                stringValues.addFile[selectedLanguage.code]
                              "
                              color="purple"
                            ></v-file-input>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="purple" text small @click="close">
                        {{ stringValues.cancel[selectedLanguage.code] }}
                      </v-btn>
                      <v-btn color="purple" text small @click="save">
                        {{ stringValues.apply[selectedLanguage.code] }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="text-subtitle-2">{{
                      stringValues.deleteSure[selectedLanguage.code]
                    }}</v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="purple" text small @click="closeDelete">
                        {{ stringValues.cancel[selectedLanguage.code] }}</v-btn
                      >
                      <v-btn
                        color="purple"
                        text
                        small
                        @click="deleteItemConfirm"
                        >{{ stringValues.apply[selectedLanguage.code] }}</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
          </v-data-table>
          <v-pagination
            circle
            v-model="list.pageNumber"
            :length="list.totalPages"
            color="purple"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "DocumentsListComponent",
  computed: {
    ...mapGetters({
      getList: "house/GET_DOCUMENTS",
      getActionStatus: "house/GET_ACTION_STATUS",
      getInfo: "house/GET_INFO",
      getCdn: "house/GET_DOCUMENT_CDN",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
      getHomeInfo: "house/GET_HOME_INFO",
    }),
    info: {
      get: function () {
        return this.getInfo;
      },
    },
    list: {
      get: function () {
        return this.getList;
      },
      set: function (newVal) {
        this.setList(newVal);
      },
    },
    actionStatus: {
      get: function () {
        return this.getActionStatus;
      },
    },
    cdn: {
      get: function () {
        return this.getCdn;
      },
    },
    formTitle() {
      return this.editedIndex === -1 ? "Добавить" : "Изменить";
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
    homeInfo: {
      get: function () {
        return this.getHomeInfo;
      },
    },
  },
  data: () => ({
    loading: false,
    headers: {
      ru: [
        {
          text: "Название",
          value: "description",
          align: "center",
          sortable: false,
          width: "40%",
          class: "black--text",
        },
        {
          text: "Ссылка",
          value: "link",
          align: "left",
          sortable: false,
          width: "40%",
          class: "black--text",
        },
        {
          text: "Дата создания",
          value: "createdDate",
          align: "center",
          sortable: false,
          width: "10%",
          class: "black--text",
        },
        {
          text: "Действия",
          value: "actions",
          align: "center",
          sortable: false,
          width: "10%",
          class: "black--text",
        },
      ],
      en: [
        {
          text: "Title",
          value: "description",
          align: "center",
          sortable: false,
          width: "40%",
          class: "black--text",
        },
        {
          text: "Link",
          value: "link",
          align: "left",
          sortable: false,
          width: "40%",
          class: "black--text",
        },
        {
          text: "Date of issue",
          value: "createdDate",
          align: "center",
          sortable: false,
          width: "10%",
          class: "black--text",
        },
        {
          text: "Actions",
          value: "actions",
          align: "center",
          sortable: false,
          width: "10%",
          class: "black--text",
        },
      ],
      tr: [
        {
          text: "Başlık",
          value: "description",
          align: "center",
          sortable: false,
          width: "40%",
          class: "black--text",
        },
        {
          text: "Referans",
          value: "link",
          align: "left",
          sortable: false,
          width: "40%",
          class: "black--text",
        },
        {
          text: "Oluşturulma tarihi",
          value: "createdDate",
          align: "center",
          sortable: false,
          width: "10%",
          class: "black--text",
        },
        {
          text: "Eylemler",
          value: "actions",
          align: "center",
          sortable: false,
          width: "10%",
          class: "black--text",
        },
      ],
    },
    dialog: false,
    dialogDelete: false,
    editedItem: {
      id: 0,
      link: "",
      description: "",
    },
    defaultItem: {
      id: 0,
      link: "",
      description: "",
    },
    editedIndex: -1,
    documentFile: null,
  }),
  methods: {
    ...mapActions({
      getListFromApi: "house/GET_DOCUMENTS_LIST_BY_RC_ID_FROM_API",
      updateFromApi: "house/UPDATE_DOCUMENTS_FROM_API",
      addFromApi: "house/ADD_DOCUMENTS_FROM_API",
      deleteFromApi: "house/DELETE_DOCUMENTS_FROM_API",
      commitMenuBtnWidth: "common/COMMIT_MENU_BTN_WIDTH",
      addToCdnFromApi: "house/ADD_DOCUMENT_TO_CDN_FROM_API",
      getHomeInfoByIdFromApi: "house/GET_HOME_INFO_BY_ID_FROM_API",
    }),
    ...mapMutations({
      setSnackBar: "common/SET_SNACKBAR",
      setList: "house/SET_DOCUMENTS",
    }),
    async getItemsFromApi(pageNumber, pageSize, rcId) {
      this.loading = true;
      await this.getListFromApi({
        pageNumber: pageNumber,
        pageSize: pageSize,
        residentialComplexId: rcId,
      });
      this.loading = false;
    },
    editItem(item) {
      this.editedIndex = this.list.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.list.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      await this.deleteFromApi({
        data: {
          documentId: this.editedItem.id,
        },
      });
      if (this.actionStatus.succeeded) {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "green",
          text: this.stringValues.ok[this.selectedLanguage.code],
        });
        this.list.data.splice(this.editedIndex, 1);
      } else {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "purple",
          text: this.stringValues.error[this.selectedLanguage.code],
        });
      }
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (this.editedIndex > -1) {
        await this.updateFromApi({
          documentId: this.editedItem.id,
          pointId: this.info.data.id,
          description: this.editedItem.description,
          link: this.editedItem.link,
        });
        if (this.actionStatus.succeeded) {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "green",
            text: this.stringValues.ok[this.selectedLanguage.code],
          });
          Object.assign(this.list.data[this.editedIndex], this.editedItem);
        } else {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "purple",
            text: this.stringValues.error[this.selectedLanguage.code],
          });
        }
      } else {
        if (this.documentFile != null && this.documentFile != undefined) {
          var base64 = await this.documentToBase64();
          base64 = base64.substring(base64.indexOf(",") + 1);

          var extensions = /[.]/.exec(this.documentFile.name)
            ? /[^.]+$/.exec(this.documentFile.name)
            : undefined;
          if (
            extensions != undefined &&
            extensions != null &&
            extensions.length > 0
          ) {
            await this.addToCdnFromApi({
              value: base64,
              extension: "." + extensions[0],
            });

            if (this.cdn.succeeded) {
              this.editedItem.link = this.cdn.data;
            } else {
              this.setSnackBar({
                show: true,
                timeout: 3000,
                color: "purple",
                text: this.stringValues.error[this.selectedLanguage.code],
              });
            }
          }
        }

        await this.addFromApi({
          pointId: this.info.data.id,
          description: this.editedItem.description,
          link: this.editedItem.link,
        });

        if (this.actionStatus.succeeded) {
          this.editedItem.createdDate = new Date();
          this.list.data.unshift(this.editedItem);
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "green",
            text: this.stringValues.ok[this.selectedLanguage.code],
          });
        } else {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "purple",
            text: this.stringValues.error[this.selectedLanguage.code],
          });
        }
      }
      this.close();
    },
    initMenuBtnWidth() {
      var btnOffsets = document
        .getElementById("menuItem_" + 6)
        .getBoundingClientRect();
      var tableOffsets = document
        .getElementById("documentsTable")
        .getBoundingClientRect();
      let width = tableOffsets.left - btnOffsets.right + btnOffsets.width + 2;
      this.commitMenuBtnWidth(width);
    },
    async documentToBase64() {
      const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });
      };

      return await getBase64(this.documentFile);
    },
  },
  watch: {
    "list.pageNumber": function () {
      this.getItemsFromApi(this.list.pageNumber, 20, this.info.data.id);
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    info: function () {
      this.initMenuBtnWidth();
      this.getItemsFromApi(1, 20, this.info.data.id);
      this.getHomeInfoByIdFromApi({ residentialComplexId: this.info.data.id });
    },
  },
  async mounted() {
    this.initMenuBtnWidth();
    window.addEventListener("resize", this.initMenuBtnWidth);
    if (this.info.succeeded) {
      await this.getItemsFromApi(1, 20, this.info.data.id);
      await this.getHomeInfoByIdFromApi({
        residentialComplexId: this.info.data.id,
      });
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.initMenuBtnWidth);
  },
};
</script>
