<template>
  <div>
    <v-row>
      <v-col cols="12" class="d-flex justify-center align-center px-12">
        <v-autocomplete
          v-model="selectedResidentialComplex"
          :items="list.data"
          :label="stringValues.changeSelection[selectedLanguage.code]"
          item-text="name"
          item-value="id"
          hide-details
          color="purple"
          return-object
        >
          <template v-slot:selection="{ item }">
            <span class="purple--text font-weight-bold">{{ item.name }}</span>
          </template>
          <template v-slot:append>
            <v-icon color="purple" style="cursor: pointer"
              >$customArrowDown</v-icon
            >
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "ResidentialComplexSelectComponent",
  computed: {
    ...mapGetters({
      getList: "house/GET_LIST",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
      getSelectedResidentialComplex: "house/GET_SELECTED_RESIDENTIAL_COMPLEX",
    }),
    list: {
      get: function () {
        return this.getList;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
    selectedResidentialComplex: {
      get: function () {
        return this.getSelectedResidentialComplex;
      },
      set: function (newVal) {
        this.setSelectedResidentialComplex(newVal);
      },
    },
  },
  data: () => ({}),
  methods: {
    ...mapActions({
      getResidentialComplexesByAdministratorIdFromApi:
        "house/GET_RESIDENTIAL_COMPLEXES_BY_ADMINISTRATOR_ID_FROM_API",
    }),
    ...mapMutations({
      setSelectedResidentialComplex: "house/SET_SELECTED_RESIDENTIAL_COMPLEX",
    }),
  },
  async created() {
    await this.getResidentialComplexesByAdministratorIdFromApi();
  },
};
</script>