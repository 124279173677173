<template>
  <v-container fluid>
    <div>
      <v-row
        v-if="selectedResidentialComplex != null"
        no-gutters
        style="height: 7vh"
      >
        <v-col
          cols="12"
          md="3"
          lg="3"
          xl="3"
          class="text-center d-flex justify-end align-center"
        >
          <v-row>
            <v-col cols="12">
              <v-card elevation="0" class="rounded-lg px-2 py-2">
                <ResidentialComplexSelectComponent />
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          md="2"
          lg="2"
          xl="2"
          class="d-flex justify-end align-center"
        >
          <LanguageSelect :align="'d-flex justify-start align-center'" />
        </v-col>
        <v-col cols="12" md="7" lg="7" xl="7" class="text-center">
          <ResidentialComplexAccountComponent />
        </v-col>
      </v-row>

      <v-row v-if="selectedResidentialComplex != null" style="height: 80vh">
        <v-col cols="12" md="3" lg="3" xl="3" class="text-center">
          <v-row>
            <v-col cols="12" class="text-left">
              <v-row>
                <v-col cols="12">
                  <span class="text-subtitle-1 ml-4" style="font-weight: 600">
                    {{ stringValues.gallery[selectedLanguage.code] }}</span
                  >
                  <v-btn
                    class="text-lowercase float-right"
                    text
                    small
                    @click="openDialog(2)"
                  >
                    <v-icon small>$customPencil</v-icon>
                    {{ stringValues.change[selectedLanguage.code] }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-card
                    elevation="0"
                    class="rounded-lg px-2 py-2 grey lighten-4"
                    max-height="40vh"
                  >
                    <ResidentialComplexImagesComponent />
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-left">
              <v-row>
                <v-col cols="12">
                  <span class="text-subtitle-1 ml-4" style="font-weight: 600">
                    {{ stringValues.details[selectedLanguage.code] }}</span
                  >
                  <v-btn
                    class="text-lowercase float-right"
                    text
                    small
                    @click="openDialog(1)"
                  >
                    <v-icon small>$customPencil</v-icon>
                    {{ stringValues.change[selectedLanguage.code] }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12">
                  <v-card
                    elevation="0"
                    class="rounded-lg px-2 py-2 overflow-y-auto"
                    max-height="40vh"
                    id="detailsDiv"
                  >
                    <ResidentialComplexMoreInformationComponent />
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="2" lg="2" xl="2">
          <ResidentialComplexMenuComponent />
        </v-col>

        <v-col cols="12" md="7" lg="7" xl="7" class="text-center px-0">
          <v-row>
            <v-col cols="12">
              <NewsListComponent v-if="selectedMenuItemId == 3" />
              <DocumentsListComponent v-else-if="selectedMenuItemId == 6" />
              <AppliancesListComponent v-else-if="selectedMenuItemId == 1" />
              <TenantsListComponent v-else-if="selectedMenuItemId == 2" />
              <AccountingMainComponent v-else-if="selectedMenuItemId == 5" />
              <MessagesComponent v-else-if="selectedMenuItemId == 7" />
              <WebCamerasComponent v-else-if="selectedMenuItemId == 4" />
              <ArchiveListComponent v-else-if="selectedMenuItemId == 8" />
              <div v-else></div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row v-else>
        <v-col cols="12">
          <v-card
            elevation="0"
            height="90vh"
            class="d-flex align-center grey lighten-4"
          >
            <v-card-text>
              <v-row no-gutters class="text-center">
                <v-col cols="12">
                  <v-icon size="64" color="red"
                    >mdi-check-circle-outline</v-icon
                  >
                </v-col>
              </v-row>
              <v-row no-gutters class="text-center">
                <v-col cols="12">
                  <span class="text-h6">{{
                    stringValues.selectRC[selectedLanguage.code]
                  }}</span>
                </v-col>
              </v-row>
              <v-row
                no-gutters
                class="text-center d-flex justify-center align-center"
              >
                <v-col cols="12" md="5" lg="5" xl="5">
                  <ResidentialComplexSelectComponent />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
import ResidentialComplexAccountComponent from "../../components/ResidentialComplex/ResidentialComplexAccountComponent.vue";
import ResidentialComplexSelectComponent from "../../components/ResidentialComplex/ResidentialComplexSelectComponent.vue";
import ResidentialComplexImagesComponent from "../../components/ResidentialComplex/ResidentialComplexImagesComponent.vue";
import ResidentialComplexMoreInformationComponent from "../../components/ResidentialComplex/ResidentialComplexMoreInformationComponent.vue";
import ResidentialComplexMenuComponent from "../../components/ResidentialComplex/ResidentialComplexMenuComponent.vue";
import NewsListComponent from "../../components/News/NewsListComponent.vue";
import DocumentsListComponent from "../../components/Documents/DocumentsListComponent.vue";
import AppliancesListComponent from "../../components/Appliances/AppliancesListComponent.vue";
import TenantsListComponent from "../../components/Tenants/TenantsListComponent.vue";
import AccountingMainComponent from "../../components/Accounting/AccountingMainComponent.vue";
import MessagesComponent from "../../components/Messages/MessagesComponent.vue";
import WebCamerasComponent from "../../components/WebCameras/WebCamerasComponent.vue";
import LanguageSelect from "../../components/Common/LanguageSelect.vue";
import ArchiveListComponent from "../../components/Archive/ArchiveListComponent.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "MainView",
  components: {
    ResidentialComplexSelectComponent,
    ResidentialComplexImagesComponent,
    ResidentialComplexMoreInformationComponent,
    ResidentialComplexMenuComponent,
    NewsListComponent,
    DocumentsListComponent,
    AppliancesListComponent,
    TenantsListComponent,
    AccountingMainComponent,
    MessagesComponent,
    ResidentialComplexAccountComponent,
    LanguageSelect,
    WebCamerasComponent,
    ArchiveListComponent,
  },
  computed: {
    ...mapGetters({
      getSelectedResidentialComplex: "house/GET_SELECTED_RESIDENTIAL_COMPLEX",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
      getSelectedMenuItemId: "house/GET_SELECTED_MENU_ITEM_ID",
    }),
    selectedResidentialComplex: {
      get: function () {
        return this.getSelectedResidentialComplex;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
    selectedMenuItemId: {
      get: function () {
        return this.getSelectedMenuItemId;
      },
      set: function (newVal) {
        this.setSelectedMenuItemId(newVal);
      },
    },
  },
  methods: {
    ...mapActions({
      getInfoByIdFromApi: "house/GET_INFO_BY_ID_FROM_API",
    }),
    ...mapMutations({
      setSelectedMenuItemId: "house/SET_SELECTED_MENU_ITEM_ID",
      setDialog: "common/SET_DIALOG",
    }),
    openDialog(id) {
      this.setDialog({
        show: true,
        fullscreen: false,
        hide_overlay: true,
        transition: "dialog-bottom-transition",
        title: this.stringValues.change[this.selectedLanguage.code],
        view_id: id,
        width: 800,
      });
    },
  },
  watch: {
    selectedResidentialComplex: function () {
      this.getInfoByIdFromApi({ id: this.selectedResidentialComplex.id });
      this.selectedMenuItemId = 1;
    },
  },
  created() {
    if (
      this.selectedResidentialComplex != undefined &&
      this.selectedResidentialComplex != null
    ) {
      this.getInfoByIdFromApi({ id: this.selectedResidentialComplex.id });
      this.selectedMenuItemId = 1;
    }
  },
};
</script>
<style>
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border-bottom: none;
}
.theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border-bottom: none;
}
#detailsDiv::-webkit-scrollbar {
  display: none;
}
</style>