<template>
  <div>
    <v-row>
      <v-col cols="12" class="d-flex justify-end align-center">
        <v-list dense color="grey lighten-4">
          <v-list-item two-line class="text-right">
            <v-list-item-content>
              <v-list-item-subtitle
                ><span class="black--text">{{
                  stringValues.loggedComplexManager[selectedLanguage.code]
                }}</span></v-list-item-subtitle
              >
              <v-list-item-title>{{
                loginInfo.data.user.firstName +
                " " +
                loginInfo.data.user.lastName
              }}</v-list-item-title>
            </v-list-item-content>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-avatar v-bind="attrs" v-on="on">
                  <v-img
                    v-if="loginInfo.data.user.imageLink != null"
                    :src="loginInfo.data.user.imageLink"
                  />
                  <v-icon v-else x-large v-bind="attrs" v-on="on"
                    >mdi-account-circle</v-icon
                  >
                </v-list-item-avatar>
              </template>
              <v-list dense>
                <v-list-item-group>
                  <v-list-item @click="logOut()">
                    <v-list-item-icon>
                      <v-icon>mdi-account-remove-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        stringValues.exit[selectedLanguage.code]
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "ResidentialComplexAccountComponent",
  computed: {
    ...mapGetters({
      getLoginInfo: "account/GET_LOGIN_INFO",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    loginInfo: {
      get: function () {
        return this.getLoginInfo;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({}),
  methods: {
    logOut() {
      sessionStorage.removeItem("selectedResidentialComplex");
      localStorage.removeItem("identity");
      document.location.reload();
    },
  },
};
</script>