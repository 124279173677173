<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="12" class="text-left">
        <span class="text-h6" style="font-weight: 600">{{
          stringValues.payments[selectedLanguage.code]
        }}</span>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" class="text-left">
        <span class="text-subtitle-2" style="font-weight: 600">{{
          stringValues.addPayment[selectedLanguage.code]
        }}</span>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" md="2" lg="2" xl="2" class="text-left pr-3">
        <span
          class="text-caption d-inline-block text-truncate"
          style="max-width: 100%"
          >{{ stringValues.apartmentNum[selectedLanguage.code] }}</span
        >
        <v-text-field
          v-model="selectedAparment"
          hide-details
          color="purple"
          dense
          outlined
          class="custom-text-field"
          @input="selectedAparment = $event !== '' ? $event : null"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="2" lg="2" xl="2" class="text-left pr-3">
        <span
          class="text-caption d-inline-block text-truncate"
          style="max-width: 100%"
          >{{ stringValues.block[selectedLanguage.code] }}</span
        >
        <v-text-field
          v-model="selectedBloc"
          hide-details
          color="purple"
          dense
          outlined
          class="custom-text-field"
          @input="selectedBloc = $event !== '' ? $event : null"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" md="2" lg="2" xl="2" class="text-left px-3">
        <span
          class="text-caption d-inline-block text-truncate"
          style="max-width: 100%"
          >{{ stringValues.amount[selectedLanguage.code] }}</span
        >
        <v-text-field
          v-model="amount"
          hide-details
          color="purple"
          dense
          type="number"
          outlined
          hide-spin-buttons
          class="custom-text-field"
          @input="amount = $event !== '' ? $event : null"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="2" lg="2" xl="2" class="text-left px-3">
        <span
          class="text-caption d-inline-block text-truncate"
          style="max-width: 100%"
          >{{ stringValues.bankCash[selectedLanguage.code] }}</span
        >
        <v-autocomplete
          v-model="selectedPaymentType"
          :items="paymentTypes.data"
          item-text="name"
          item-value="id"
          hide-details
          color="purple"
          dense
          return-object
          outlined
          class="custom-text-field"
          @input="selectedPaymentType = $event !== '' ? $event : null"
        >
          <template v-slot:append>
            <v-icon color="purple" small>$customDown</v-icon>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="2" lg="2" xl="2" class="text-left px-3">
        <span
          class="text-caption d-inline-block text-truncate"
          style="max-width: 100%"
          >{{ stringValues.date[selectedLanguage.code] }}</span
        >
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              hide-details
              color="purple"
              dense
              outlined
              readonly
              v-bind="attrs"
              v-on="on"
              class="custom-text-field"
              @input="date = $event !== '' ? $event : null"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            no-title
            scrollable
            color="purple"
            :locale="
              selectedLanguage != undefined && selectedLanguage != null
                ? selectedLanguage.code
                : 'en'
            "
          >
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" md="2" lg="2" xl="2" class="text-left pl-3">
        <span
          class="text-caption d-inline-block text-truncate"
          style="max-width: 100%"
          >{{ stringValues.comment[selectedLanguage.code] }}</span
        >
        <v-text-field
          v-model="comment"
          hide-details
          color="purple"
          dense
          outlined
          class="custom-text-field"
          @input="comment = $event !== '' ? $event : null"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-2">
      <v-col cols="12" class="text-right">
        <v-btn
          :class="
            date != null ||
            selectedAparment != null ||
            amount != null ||
            comment != null ||
            selectedBloc != null
              ? 'green white--text rounded-lg text-capitalize'
              : 'grey white--text rounded-lg text-capitalize'
          "
          small
          elevation="0"
          @click="addPayment()"
          style="font-weight: 600"
          >{{ stringValues.recordPayment[selectedLanguage.code] }}</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "ReceiptsSelect",
  computed: {
    ...mapGetters({
      getLanguages: "common/GET_LANGUAGES",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
      getInfo: "house/GET_INFO",
      getPaymentTypes: "house/GET_PAYMENT_TYPES",
      getApartments: "house/GET_APARTMENTS",
      getActionStatus: "house/GET_ACTION_STATUS",
    }),
    languages: {
      get: function () {
        return this.getLanguages;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
    info: {
      get: function () {
        return this.getInfo;
      },
    },
    apartments: {
      get: function () {
        return this.getApartments;
      },
    },
    paymentTypes: {
      get: function () {
        return this.getPaymentTypes;
      },
    },
    actionStatus: {
      get: function () {
        return this.getActionStatus;
      },
    },
  },
  data: () => ({
    date: null,
    menu: false,
    selectedAparment: null,
    selectedPaymentType: null,
    amount: null,
    comment: null,
    selectedBloc: null,
  }),
  methods: {
    ...mapActions({
      getApartmentsFromApi: "house/GET_APARTMENTS_LIST_BY_RC_ID_FROM_API",
      getPaymentTypesFromApi: "house/GET_PAYMENT_TYPES_LIST_BY_RC_ID_FROM_API",
      addPaymentFromApi: "house/ADD_PAYMENT_FROM_API",
    }),
    ...mapMutations({
      setSnackBar: "common/SET_SNACKBAR",
      setList: "house/SET_DOCUMENTS",
    }),
    async addPayment() {
      await this.addPaymentFromApi({
        apartmentNum: this.selectedAparment,
        bloc: this.selectedBloc,
        residentialComplexId: this.info.data.id,
        amount: this.amount,
        paymentDate: this.date,
        paymentTypeId: this.selectedPaymentType.id,
        comment: this.comment,
      });
      if (this.actionStatus.succeeded) {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "green",
          text: this.stringValues.ok[this.selectedLanguage.code],
        });
      } else {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "purple",
          text: this.stringValues.error[this.selectedLanguage.code],
        });
      }
    },
  },
  // watch: {
  // info: function () {
  //   this.getApartmentsFromApi({ residentialComplexId: this.info.data.id });
  // },
  // },
  async created() {
    // if (this.info.succeeded)
    //   this.getApartmentsFromApi({ residentialComplexId: this.info.data.id });

    await this.getPaymentTypesFromApi();

    if (this.paymentTypes.succeeded && this.paymentTypes.data.length > 0) {
      this.selectedPaymentType = this.paymentTypes.data[0];
    }
  },
};
</script>
<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #a438cd;
}
</style>