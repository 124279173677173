<template>
  <v-container>
    <v-card elevation="0" class="rounded-lg">
      <v-row>
        <v-col cols="12">
          <v-alert color="#D9D9D9" dense class="rounded-lg">
            <v-row no-gutters>
              <v-col cols="10" class="text-left">
                <span class="text-subtitle-2" style="font-weight: 600">{{
                  stringValues.incomingBalance[selectedLanguage.code]
                }}</span>
              </v-col>
              <v-col cols="2" class="text-right">
                <span class="text-subtitle-2" style="font-weight: 600">{{
                  report.data.incomingBalance
                }}</span>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
      </v-row>
      <v-card-title>
        <span style="font-weight: 600">{{
          stringValues.payments[selectedLanguage.code]
        }}</span>
      </v-card-title>
      <v-row class="d-flex align-center">
        <v-col cols="12">
          <v-data-table
            :headers="headers[selectedLanguage.code]"
            :items="report.data.payments"
            :items-per-page="-1"
            class="elevation-0"
            hide-default-footer
            :loading="loading"
            height="30vh"
            dense
          >
            <template slot="progress">
              <v-progress-linear
                color="purple"
                indeterminate
              ></v-progress-linear>
            </template>
            <template v-slot:[`item.apartmentNum`]="{ item }">
              <span class="text-caption" style="font-weight: 600">{{
                (item.bloc == null ? "" : item.bloc) +
                (item.bloc == null ||
                item.apartmentNum == null ||
                item.bloc == "" ||
                item.apartmentNum == ""
                  ? ""
                  : "/") +
                (item.apartmentNum == null ? "" : item.apartmentNum)
              }}</span>
            </template>
            <template v-slot:[`item.paymentDate`]="{ item }">
              <span>{{ new Date(item.paymentDate).toLocaleDateString() }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="editItem(item)">
                $customPencil
              </v-icon>
              <v-icon small @click="deleteItem(item)" color="red">
                $customTrash
              </v-icon>
            </template>

            <template v-slot:top>
              <v-dialog v-model="dialog" max-width="500px">
                <v-card>
                  <v-card-title>
                    <span class="text-subtitle-2">{{
                      editedIndex === -1
                        ? stringValues.add[selectedLanguage.code]
                        : stringValues.change[selectedLanguage.code]
                    }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.amount"
                            hide-details
                            color="purple"
                            dense
                            type="number"
                            :label="stringValues.amount[selectedLanguage.code]"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="editedItem.paymentDate"
                                hide-details
                                color="purple"
                                dense
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :label="
                                  stringValues.paymentDate[
                                    selectedLanguage.code
                                  ]
                                "
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="editedItem.paymentDate"
                              no-title
                              scrollable
                              color="purple"
                              :locale="
                                selectedLanguage != undefined &&
                                selectedLanguage != null
                                  ? selectedLanguage.code
                                  : 'en'
                              "
                            >
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12">
                          <v-textarea
                            v-model="editedItem.comment"
                            :label="stringValues.comment[selectedLanguage.code]"
                            color="purple"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="purple" text small @click="close">
                      {{ stringValues.cancel[selectedLanguage.code] }}
                    </v-btn>
                    <v-btn color="purple" text small @click="save">
                      {{ stringValues.apply[selectedLanguage.code] }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-subtitle-2">{{
                    stringValues.deleteSure[selectedLanguage.code]
                  }}</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="purple" text small @click="closeDelete">
                      {{ stringValues.cancel[selectedLanguage.code] }}</v-btn
                    >
                    <v-btn
                      color="purple"
                      text
                      small
                      @click="deleteItemConfirm"
                      >{{ stringValues.apply[selectedLanguage.code] }}</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-alert color="#D9D9D9" dense class="rounded-lg">
            <v-row no-gutters>
              <v-col cols="10" class="text-left">
                <span class="text-subtitle-2" style="font-weight: 600">{{
                  stringValues.totalArrival[selectedLanguage.code]
                }}</span>
              </v-col>
              <v-col cols="2" class="text-right">
                <span class="text-subtitle-2" style="font-weight: 600">{{
                  report.data.totalPayments
                }}</span>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "ReceiptsReportComponent",
  computed: {
    ...mapGetters({
      getReport: "house/GET_REPORT",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
      getActionStatus: "house/GET_ACTION_STATUS",
      getInfo: "house/GET_INFO",
    }),
    info: {
      get: function () {
        return this.getInfo;
      },
    },
    report: {
      get: function () {
        return this.getReport;
      },
      set: function (newVal) {
        this.setReport(newVal);
      },
    },
    actionStatus: {
      get: function () {
        return this.getActionStatus;
      },
    },
    formTitle() {
      return this.editedIndex === -1 ? "Добавить" : "Изменить";
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({
    date: null,
    menu: false,
    loading: false,
    headers: {
      ru: [
        {
          text: "Номер кв",
          value: "apartmentNum",
          align: "center",
          width: "10%",
        },
        {
          text: "СУММА",
          value: "amount",
          align: "center",
          width: "15%",
        },
        {
          text: "Наличные/банк",
          value: "paymentTypeName",
          align: "center",
          width: "10%",
        },
        {
          text: "Дата оплаты",
          value: "paymentDate",
          align: "center",
          width: "15%",
        },
        {
          text: "Комментарий",
          value: "comment",
          align: "left",
          width: "40%",
        },
        {
          text: "",
          value: "actions",
          align: "center",
          sortable: false,
          width: "10%",
        },
      ],
      en: [
        {
          text: "Apartment num",
          value: "apartmentNum",
          align: "center",
          width: "10%",
        },
        {
          text: "AMOUNT",
          value: "amount",
          align: "center",
          width: "15%",
        },
        {
          text: "Bank/Cash",
          value: "paymentTypeName",
          align: "center",
          width: "10%",
        },
        {
          text: "Payment date",
          value: "paymentDate",
          align: "center",
          width: "15%",
        },
        {
          text: "Comment",
          value: "comment",
          align: "left",
          width: "40%",
        },
        {
          text: "",
          value: "actions",
          align: "center",
          sortable: false,
          width: "10%",
        },
      ],
      tr: [
        {
          text: "Apartman numa",
          value: "apartmentNum",
          align: "center",
          width: "10%",
        },
        {
          text: "TOPLAM",
          value: "amount",
          align: "center",
          width: "15%",
        },
        {
          text: "Banka/Nakit",
          value: "paymentTypeName",
          align: "center",
          width: "10%",
        },
        {
          text: "ödeme günü",
          value: "paymentDate",
          align: "center",
          width: "15%",
        },
        {
          text: "Comment",
          value: "comment",
          align: "left",
          width: "40%",
        },
        {
          text: "",
          value: "actions",
          align: "center",
          sortable: false,
          width: "10%",
        },
      ],
    },
    dialog: false,
    dialogDelete: false,
    editedItem: {
      id: 0,
      amount: 0,
      paymentDate: "",
      comment: "",
      paymentTypeId: 0,
      apartmentNum: "",
    },
    defaultItem: {
      d: 0,
      amount: 0,
      paymentDate: "",
      comment: "",
      paymentTypeId: 0,
      apartmentNum: "",
    },
    editedIndex: -1,
  }),
  methods: {
    ...mapActions({
      updateFromApi: "house/UPDATE_PAYMENT_FROM_API",
      deleteFromApi: "house/DELETE_PAYMENT_FROM_API",
    }),
    ...mapMutations({
      setSnackBar: "common/SET_SNACKBAR",
      setReport: "house/SET_REPORT",
    }),
    editItem(item) {
      this.editedIndex = this.report.data.payments.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.report.data.payments.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      await this.deleteFromApi({
        data: {
          id: this.editedItem.id,
        },
      });
      if (this.actionStatus.succeeded) {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "green",
          text: this.stringValues.ok[this.selectedLanguage.code],
        });
        this.report.data.payments.splice(this.editedIndex, 1);
      } else {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "purple",
          text: this.stringValues.error[this.selectedLanguage.code],
        });
      }
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (this.editedIndex > -1) {
        await this.updateFromApi({
          paymentDate: this.editedItem.paymentDate,
          comment: this.editedItem.comment,
          amount: this.editedItem.amount,
          id: this.editedItem.id,
          paymentTypeId: this.editedItem.paymentTypeId,
          apartmentNum: this.editedItem.apartmentNum,
        });
        if (this.actionStatus.succeeded) {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "green",
            text: this.stringValues.ok[this.selectedLanguage.code],
          });
          Object.assign(
            this.report.data.payments[this.editedIndex],
            this.editedItem
          );
        } else {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "purple",
            text: this.stringValues.error[this.selectedLanguage.code],
          });
        }
      }
      this.close();
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
};
</script>
