import translate from '../api/translate/index'

const Get = (query) => {
    return new Promise((resolve) => {
        resolve(translate.translate.Get(query))
    })
}


export default {
    namespaced: true,
    state: {
        info: null
    },
    getters: {
        GET_INFO(state) {
            return state.info;
        },
    },
    mutations: {
        SET_INFO(state, payload) {
            state.info = payload;
        },
    },
    actions: {
        async GET_FROM_API({ commit }, payload) {
            await Get(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_INFO', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_INFO', { succeeded: false });
                })
        },
    }
}