<template>
  <v-container>
    <v-row v-if="info.succeeded">
      <v-col cols="12">
        <v-row
          v-for="(item, index) in info.data.groupedParameterValues"
          :key="item.parameterId"
          no-gutters
          :class="index == 0 ? '' : 'my-2'"
        >
          <v-col cols="6" class="text-left">
            <h4 class="font-weight-medium text-body-2 grey--text">
              {{ item.parameter.name }}:
            </h4>
          </v-col>
          <v-col cols="6" class="text-right">
            <h4 class="black--text text-body-2" style="font-weight: 600">
              {{
                item.pointParameterValueItemList
                  .map((x) => x.parameterCatalog.name)
                  .join()
              }}
            </h4>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-card elevation="0" width="100%" v-else class="grey lighten-4">
      <v-skeleton-loader
        height="30vh"
        width="100%"
        type="card"
      ></v-skeleton-loader>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "ResidentialComplexMoreInformationComponent",
  computed: {
    ...mapGetters({
      getInfo: "house/GET_INFO",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    info: {
      get: function () {
        return this.getInfo;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({}),
};
</script>
