export default function (instance) {
    return {
        UpdatePointById(payload) {
            return instance.post(`/Point/UpdatePointById`, payload);
        },
        GetParameters() {
            return instance.get(`/PointCategory/GetAll`);
        },
    }
}
