import house from '../api/house/index'

const GetResidentialComplexesByAdministratorId = () => {
    return new Promise((resolve) => {
        resolve(house.house.GetResidentialComplexesByAdministratorId())
    })
}

const GetById = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.GetById(query))
    })
}

const GetPagedNewsByUserIdAndResidentialComplexId = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.GetPagedNewsByUserIdAndResidentialComplexId(query))
    })
}

const UpdateNews = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.UpdateNews(query))
    })
}

const DeleteNews = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.DeleteNews(query))
    })
}

const AddNews = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.AddNews(query))
    })
}

const GetPagedDocumentsByUserIdAndResidentialComplexId = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.GetPagedDocumentsByUserIdAndResidentialComplexId(query))
    })
}

const UpdateDocuments = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.UpdateDocuments(query))
    })
}

const DeleteDocuments = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.DeleteDocuments(query))
    })
}

const AddDocuments = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.AddDocuments(query))
    })
}

const GetPagedApplianceByUserIdAndResidentialComplexId = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.GetPagedApplianceByUserIdAndResidentialComplexId(query))
    })
}

const UpdateAppliance = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.UpdateAppliance(query))
    })
}

const GetPagedTenantsByResidentialComplexId = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.GetPagedTenantsByResidentialComplexId(query))
    })
}

const GetApartmentsByResidentialComplexId = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.GetApartmentsByResidentialComplexId(query))
    })
}

const GetPaymentTypes = () => {
    return new Promise((resolve) => {
        resolve(house.house.GetPaymentTypes())
    })
}

const GetCostTypesExpendituresByResidentialComplexId = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.GetCostTypesExpendituresByResidentialComplexId(query))
    })
}

const AddPayment = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.AddPayment(query))
    })
}

const AddExpense = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.AddExpense(query))
    })
}

const GetAccountingReportByResidentialComplexId = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.GetAccountingReportByResidentialComplexId(query))
    })
}

const DeletePayment = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.DeletePayment(query))
    })
}

const UpdatePayment = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.UpdatePayment(query))
    })
}

const DeleteExpense = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.DeleteExpense(query))
    })
}

const UpdateExpense = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.UpdateExpense(query))
    })
}

const GetExcelAccountingReportByResidentialComplexId = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.GetExcelAccountingReportByResidentialComplexId(query))
    })
}

const GetPdfAccountingReportByResidentialComplexId = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.GetPdfAccountingReportByResidentialComplexId(query))
    })
}

const AddDocumentToCdn = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.AddDocumentToCdn(query))
    })
}

const GetHomeInfo = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.GetHomeInfo(query))
    })
}

const GetApplianceStatuses = () => {
    return new Promise((resolve) => {
        resolve(house.house.GetApplianceStatuses())
    })
}

const DeleteResidentialComplexUser = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.DeleteResidentialComplexUser(query))
    })
}

const GetPagedArchivesByResidentialComplexId = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.GetPagedArchivesByResidentialComplexId(query))
    })
}

const DeleteArchive = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.DeleteArchive(query))
    })
}

const AddArchive = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.AddArchive(query))
    })
}

const GetApartment = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.GetApartment(query))
    })
}

const UpdateResidentialComplexApartmentDebt = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.UpdateResidentialComplexApartmentDebt(query))
    })
}

const UpdateResidentialComplexApartmentNote = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.UpdateResidentialComplexApartmentNote(query))
    })
}

const UpdateResidentialComplexApartmentStatus = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.UpdateResidentialComplexApartmentStatus(query))
    })
}

const AddApartmentAttachment = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.AddApartmentAttachment(query))
    })
}

const DeleteApartmentAttachment = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.DeleteApartmentAttachment(query))
    })
}

const GetExcelTenantsReportByResidentialComplexId = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.GetExcelTenantsReportByResidentialComplexId(query))
    })
}

const GetPdfTenantsReportByResidentialComplexId = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.GetPdfTenantsReportByResidentialComplexId(query))
    })
}


const AddResidentialComplexUser = (query) => {
    return new Promise((resolve) => {
        resolve(house.house.AddResidentialComplexUser(query))
    })
}

export default {
    namespaced: true,
    state: {
        list: [],
        selectedResidentialComplex: null,
        info: {
            succeeded: false
        },
        news: {
            pageNumber: 1,
            totalRecords: 0,
        },
        actionStatus: null,
        selectedMenuItemId: 0,
        documents: {
            pageNumber: 1,
            totalRecords: 0,
        },
        appliances: {
            pageNumber: 1,
            totalRecords: 0,
        },
        tenants: {
            pageNumber: 1,
            totalRecords: 0,
        },
        apartments: {
            succeeded: false
        },
        paymentTypes: {
            succeeded: false
        },
        costTypes: {
            succeeded: false
        },
        report: {
            succeeded: false
        },
        fileStatus: {
            succeeded: false
        },
        documentCdn: {
            succeeded: false
        },
        homeInfo: {
            succeeded: false
        },
        applianceStatuses: {
            succeeded: false
        },
        archives: {
            pageNumber: 1,
            totalRecords: 0,
        },
        apartment: {
            succeeded: false
        },
    },
    getters: {
        GET_LIST(state) {
            return state.list;
        },
        GET_SELECTED_RESIDENTIAL_COMPLEX(state) {
            if (sessionStorage.getItem('selectedResidentialComplex') != null) {
                var rcObj = JSON.parse(sessionStorage.getItem('selectedResidentialComplex'))
                state.selectedResidentialComplex = rcObj;
            }
            return state.selectedResidentialComplex;
        },
        GET_INFO(state) {
            return state.info;
        },
        GET_NEWS(state) {
            return state.news;
        },
        GET_ACTION_STATUS(state) {
            return state.actionStatus;
        },
        GET_SELECTED_MENU_ITEM_ID(state) {
            return state.selectedMenuItemId;
        },
        GET_DOCUMENTS(state) {
            return state.documents;
        },
        GET_APPLIANCES(state) {
            return state.appliances;
        },
        GET_TENANTS(state) {
            return state.tenants;
        },
        GET_APARTMENTS(state) {
            return state.apartments;
        },
        GET_PAYMENT_TYPES(state) {
            return state.paymentTypes;
        },
        GET_COST_TYPES(state) {
            return state.costTypes;
        },
        GET_REPORT(state) {
            return state.report;
        },
        GET_FILE_STATUS(state) {
            return state.fileStatus;
        },
        GET_DOCUMENT_CDN(state) {
            return state.documentCdn;
        },
        GET_HOME_INFO(state) {
            return state.homeInfo;
        },
        GET_APPLIANCE_STATUSES(state) {
            return state.applianceStatuses;
        },
        GET_ARCHIVES(state) {
            return state.archives;
        },
        GET_APARTMENT(state) {
            return state.apartment;
        },
    },
    mutations: {
        SET_LIST(state, payload) {
            state.list = payload;
        },
        SET_SELECTED_RESIDENTIAL_COMPLEX(state, payload) {
            sessionStorage.setItem('selectedResidentialComplex', JSON.stringify(payload));
            state.selectedResidentialComplex = payload;
        },
        SET_INFO(state, payload) {
            state.info = payload;
        },
        SET_NEWS(state, payload) {
            state.news = payload;
        },
        SET_ACTION_STATUS(state, payload) {
            state.actionStatus = payload;
        },
        SET_SELECTED_MENU_ITEM_ID(state, payload) {
            state.selectedMenuItemId = payload;
        },
        SET_DOCUMENTS(state, payload) {
            state.documents = payload;
        },
        SET_TENANTS(state, payload) {
            state.tenants = payload;
        },
        SET_APPLIANCES(state, payload) {
            state.appliances = payload;
        },
        SET_APARTMENTS(state, payload) {
            state.apartments = payload;
        },
        SET_PAYMENT_TYPES(state, payload) {
            state.paymentTypes = payload;
        },
        SET_COST_TYPES(state, payload) {
            state.costTypes = payload;
        },
        SET_REPORT(state, payload) {
            state.report = payload;
        },
        SET_FILE_STATUS(state, payload) {
            state.fileStatus = payload;
        },
        SET_DOCUMENT_CDN(state, payload) {
            state.documentCdn = payload;
        },
        SET_HOME_INFO(state, payload) {
            state.homeInfo = payload;
        },
        SET_APPLIANCE_STATUSES(state, payload) {
            state.applianceStatuses = payload;
        },
        SET_ARCHIVES(state, payload) {
            state.archives = payload;
        },
        SET_APARTMENT(state, payload) {
            state.apartment = payload;
        },
    },
    actions: {
        async GET_RESIDENTIAL_COMPLEXES_BY_ADMINISTRATOR_ID_FROM_API({ commit }) {
            await GetResidentialComplexesByAdministratorId().then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_LIST', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_LIST', { succeeded: false });
                })
        },
        SELECT_RESIDENTIAL_COMPLEX({ commit }, payload) {
            commit('SET_SELECTED_RESIDENTIAL_COMPLEX', payload);
        },
        async GET_INFO_BY_ID_FROM_API({ commit }, payload) {
            await GetById(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_INFO', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_INFO', { succeeded: false });
                })
        },
        async GET_NEWS_LIST_BY_RC_ID_FROM_API({ commit }, payload) {
            await GetPagedNewsByUserIdAndResidentialComplexId(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_NEWS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_NEWS', { succeeded: false });
                })
        },
        async UPDATE_NEWS_FROM_API({ commit }, payload) {
            await UpdateNews(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ACTION_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ACTION_STATUS', { succeeded: false });
                })
        },
        async DELETE_NEWS_FROM_API({ commit }, payload) {
            await DeleteNews(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ACTION_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ACTION_STATUS', { succeeded: false });
                })
        },
        async ADD_NEWS_FROM_API({ commit }, payload) {
            await AddNews(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ACTION_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ACTION_STATUS', { succeeded: false });
                })
        },
        SELECT_MENU_ITEM_ID({ commit }, payload) {
            commit('SET_SELECTED_MENU_ITEM_ID', payload);
        },
        async GET_DOCUMENTS_LIST_BY_RC_ID_FROM_API({ commit }, payload) {
            await GetPagedDocumentsByUserIdAndResidentialComplexId(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_DOCUMENTS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_DOCUMENTS', { succeeded: false });
                })
        },
        async UPDATE_DOCUMENTS_FROM_API({ commit }, payload) {
            await UpdateDocuments(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ACTION_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ACTION_STATUS', { succeeded: false });
                })
        },
        async DELETE_DOCUMENTS_FROM_API({ commit }, payload) {
            await DeleteDocuments(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ACTION_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ACTION_STATUS', { succeeded: false });
                })
        },
        async ADD_DOCUMENTS_FROM_API({ commit }, payload) {
            await AddDocuments(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ACTION_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ACTION_STATUS', { succeeded: false });
                })
        },
        async GET_APPLICANCES_LIST_BY_RC_ID_FROM_API({ commit }, payload) {
            await GetPagedApplianceByUserIdAndResidentialComplexId(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_APPLIANCES', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_APPLIANCES', { succeeded: false });
                })
        },
        async UPDATE_APPLIANCE_FROM_API({ commit }, payload) {
            await UpdateAppliance(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ACTION_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ACTION_STATUS', { succeeded: false });
                })
        },
        async GET_TENANTS_LIST_BY_RC_ID_FROM_API({ commit }, payload) {
            await GetPagedTenantsByResidentialComplexId(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_TENANTS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_TENANTS', { succeeded: false });
                })
        },
        async GET_APARTMENTS_LIST_BY_RC_ID_FROM_API({ commit }, payload) {
            await GetApartmentsByResidentialComplexId(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_APARTMENTS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_APARTMENTS', { succeeded: false });
                })
        },
        async GET_PAYMENT_TYPES_LIST_BY_RC_ID_FROM_API({ commit }) {
            await GetPaymentTypes().then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_PAYMENT_TYPES', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_PAYMENT_TYPES', { succeeded: false });
                })
        },
        async GET_COST_TYPES_EXPENDITURES_LIST_BY_RC_ID_FROM_API({ commit }, payload) {
            await GetCostTypesExpendituresByResidentialComplexId(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_COST_TYPES', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_COST_TYPES', { succeeded: false });
                })
        },
        async ADD_PAYMENT_FROM_API({ commit }, payload) {
            await AddPayment(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ACTION_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ACTION_STATUS', { succeeded: false });
                })
        },
        async ADD_EXPENSE_FROM_API({ commit }, payload) {
            await AddExpense(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ACTION_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ACTION_STATUS', { succeeded: false });
                })
        },
        async GET_REPORT_FROM_API({ commit }, payload) {
            await GetAccountingReportByResidentialComplexId(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_REPORT', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_REPORT', { succeeded: false });
                })
        },
        async UPDATE_PAYMENT_FROM_API({ commit }, payload) {
            await UpdatePayment(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ACTION_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ACTION_STATUS', { succeeded: false });
                })
        },
        async DELETE_PAYMENT_FROM_API({ commit }, payload) {
            await DeletePayment(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ACTION_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ACTION_STATUS', { succeeded: false });
                })
        },
        async UPDATE_EXPENSE_FROM_API({ commit }, payload) {
            await UpdateExpense(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ACTION_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ACTION_STATUS', { succeeded: false });
                })
        },
        async DELETE_EXPENSE_FROM_API({ commit }, payload) {
            await DeleteExpense(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ACTION_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ACTION_STATUS', { succeeded: false });
                })
        },
        async GET_EXCEL_REPORT_FROM_API({ commit }, payload) {
            await GetExcelAccountingReportByResidentialComplexId(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_FILE_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_FILE_STATUS', { succeeded: false });
                })
        },
        async GET_PDF_REPORT_FROM_API({ commit }, payload) {
            await GetPdfAccountingReportByResidentialComplexId(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_FILE_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_FILE_STATUS', { succeeded: false });
                })
        },
        async ADD_DOCUMENT_TO_CDN_FROM_API({ commit }, payload) {
            await AddDocumentToCdn(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_DOCUMENT_CDN', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_DOCUMENT_CDN', { succeeded: false });
                })
        },
        async GET_HOME_INFO_BY_ID_FROM_API({ commit }, payload) {
            await GetHomeInfo(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_HOME_INFO', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_HOME_INFO', { succeeded: false });
                })
        },
        async GET_APPLIANCE_STATUSES_FROM_API({ commit }) {
            await GetApplianceStatuses().then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_APPLIANCE_STATUSES', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_APPLIANCE_STATUSES', { succeeded: false });
                })
        },
        async DELETE_RESIDENTIAL_COMPLEX_USER_FROM_API({ commit }, payload) {
            await DeleteResidentialComplexUser(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ACTION_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ACTION_STATUS', { succeeded: false });
                })
        },
        async GET_ARCHIVES_LIST_BY_RC_ID_FROM_API({ commit }, payload) {
            await GetPagedArchivesByResidentialComplexId(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ARCHIVES', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ARCHIVES', { succeeded: false });
                })
        },
        async DELETE_ARCHIVE_FROM_API({ commit }, payload) {
            await DeleteArchive(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ACTION_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ACTION_STATUS', { succeeded: false });
                })
        },
        async ADD_ARCHIVE_FROM_API({ commit }, payload) {
            await AddArchive(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ACTION_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ACTION_STATUS', { succeeded: false });
                })
        },
        async GET_APARTMENT_FROM_API({ commit }, payload) {
            await GetApartment(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_APARTMENT', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_APARTMENT', { succeeded: false });
                })
        },
        async UPDATE_APARTMENT_DEBT_FROM_API({ commit }, payload) {
            await UpdateResidentialComplexApartmentDebt(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ACTION_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ACTION_STATUS', { succeeded: false });
                })
        },
        async UPDATE_APARTMENT_NOTE_FROM_API({ commit }, payload) {
            await UpdateResidentialComplexApartmentNote(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ACTION_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ACTION_STATUS', { succeeded: false });
                })
        },
        async ADD_APARTMENT_ATTACHMENT_FROM_API({ commit }, payload) {
            await AddApartmentAttachment(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ACTION_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ACTION_STATUS', { succeeded: false });
                })
        },
        async DELETE_APARTMENT_ATTACHMENT_FROM_API({ commit }, payload) {
            await DeleteApartmentAttachment(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ACTION_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ACTION_STATUS', { succeeded: false });
                })
        },
        async GET_TENANTS_EXCEL_REPORT_FROM_API({ commit }, payload) {
            await GetExcelTenantsReportByResidentialComplexId(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_FILE_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_FILE_STATUS', { succeeded: false });
                })
        },
        async GET_TENANTS_PDF_REPORT_FROM_API({ commit }, payload) {
            await GetPdfTenantsReportByResidentialComplexId(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_FILE_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_FILE_STATUS', { succeeded: false });
                })
        },
        async UPDATE_APARTMENT_STATUS_FROM_API({ commit }, payload) {
            await UpdateResidentialComplexApartmentStatus(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ACTION_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ACTION_STATUS', { succeeded: false });
                })
        },
        async ADD_RESIDENTIAL_COMPLEX_USER_FROM_API({ commit }, payload) {
            await AddResidentialComplexUser(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ACTION_STATUS', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_ACTION_STATUS', { succeeded: false });
                })
        },
    }
}