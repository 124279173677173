<template>
  <v-container-fluid>
    <v-row class="grey lighten-4" no-gutters>
      <v-col cols="3" md="1" lg="1" xl="1">
        <CustomSidebar
          class="py-4"
          :align="'d-flex justify-center align-center'"
        />
      </v-col>
      <v-col cols="9" md="11" lg="11" xl="11">
        <v-row style="height: 90vh" class="d-flex align-center justify-center">
          <v-col cols="12" md="4" lg="4" xl="4" class="text-center">
            <div>
              <span class="text-h5 font-weight-bold">{{
                stringValues.welcome[selectedLanguage.code]
              }}</span>
            </div>
            <div>
              <span class="text-h6 font-weight-mediun">{{
                stringValues.elHomesTitle[selectedLanguage.code]
              }}</span>
            </div>
            <div class="mb-6">
              <span class="text-h6 font-weight-mediun purple--text">{{
                stringValues.elHomes[selectedLanguage.code]
              }}</span>
            </div>
            <div class="text-center my-6">
              <LoginComponent />
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container-fluid>
</template>
<script>
import LoginComponent from "../../components/Account/LoginComponent.vue";
import CustomSidebar from "../../components/Common/CustomSidebar.vue";
import { mapGetters } from "vuex";
export default {
  name: "LoginView",
  components: {
    CustomSidebar,
    LoginComponent,
  },
  computed: {
    ...mapGetters({
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
};
</script>