import account from '../api/identity/index'

const Login = (query) => {
    return new Promise((resolve) => {
        resolve(account.account.Login(query))
    })
}


export default {
    namespaced: true,
    state: {
        loginInfo: null,
        registerInfo: null,
        changeStatus: null,
        users: null
    },
    getters: {
        GET_LOGIN_INFO(state) {
            if (localStorage.getItem('identity') != null) {
                var identityObj = JSON.parse(localStorage.getItem('identity'))
                identityObj = { data: identityObj };
                state.loginInfo = identityObj.data;
            }
            return state.loginInfo;
        },
    },
    mutations: {
        SET_LOGIN_INFO(state, payload) {
            if (payload.succeeded)
                localStorage.setItem('identity', JSON.stringify(payload));

            state.loginInfo = payload;
        },
    },
    actions: {
        async LOGIN_FROM_API({ commit }, payload) {
            await Login(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_LOGIN_INFO', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_LOGIN_INFO', { succeeded: false });
                })
        },
    }
}