<template>
  <v-container>
    <v-card
      elevation="0"
      height="80vh"
      class="overflow-y-auto px-1 py-1"
      style="border: 1px solid #a438b6"
      id="accountingTable"
    >
      <div v-if="!isReportWindow">
        <v-row>
          <v-col cols="12">
            <ReceiptsComponent />
            <v-divider />
            <ExpensesComponent />
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <v-row>
          <v-col cols="12" class="text-left">
            <v-btn
              small
              text
              class="text-capitalize"
              color="purple"
              @click="isReportWindow = false"
              ><v-icon small>mdi-arrow-left</v-icon>
              {{ stringValues.back[selectedLanguage.code] }}</v-btn
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="8" lg="8" xl="8" class="text-left ml-4 mt-4">
            <div>
              <span class="text-h5 font-weight-bold purple--text">{{
                info.data.name
              }}</span>
            </div>
            <div>
              <span class="text-subtitle-1" style="font-weight: 600"
                >{{
                  stringValues.financeReportForPeriod[selectedLanguage.code]
                }}: {{ new Date(dateReportFrom).toLocaleDateString() }} -
                {{ new Date(dateReportTo).toLocaleDateString() }}</span
              >
            </div>
          </v-col>
          <v-col cols="12" md="3" lg="3" xl="3" class="text-center mt-4">
            <v-btn text>
              <v-icon
                size="36"
                color="green"
                @click="generateExcel()"
                :loading="loading"
                :disabled="loading"
                >$customExcel</v-icon
              >
            </v-btn>
            <v-btn text>
              <v-icon
                size="36"
                color="red"
                @click="generatePdf()"
                :loading="loading"
                :disabled="loading"
                >$customPdf</v-icon
              >
            </v-btn>
          </v-col>
        </v-row>
        <v-divider />
        <v-row>
          <v-col cols="12">
            <ReceiptsReportComponent />
          </v-col>
        </v-row>
        <v-divider />
        <v-row>
          <v-col cols="12">
            <ExpensesReportComponent />
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-dialog v-model="reportDialog" max-width="500px">
      <v-card class="py-4">
        <v-card-text>
          <v-row>
            <v-col cols="12" class="text-center">
              <span class="text-subtitle-2 font-weight-bold">{{
                stringValues.generateReportForPeriod[selectedLanguage.code]
              }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" lg="6" xl="6" class="text-center">
              <v-menu
                ref="menuReportFrom"
                v-model="menuReportFrom"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateReportFrom"
                    hide-details
                    color="purple"
                    dense
                    outlined
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :label="stringValues.from[selectedLanguage.code]"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateReportFrom"
                  no-title
                  scrollable
                  color="purple"
                  :locale="
                    selectedLanguage != undefined && selectedLanguage != null
                      ? selectedLanguage.code
                      : 'en'
                  "
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6" lg="6" xl="6" class="text-center">
              <v-menu
                ref="menuReportTo"
                v-model="menuReportTo"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateReportTo"
                    hide-details
                    color="purple"
                    dense
                    outlined
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :label="stringValues.to[selectedLanguage.code]"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateReportTo"
                  no-title
                  scrollable
                  color="purple"
                  :locale="
                    selectedLanguage != undefined && selectedLanguage != null
                      ? selectedLanguage.code
                      : 'en'
                  "
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-center">
              <v-btn
                class="purple white--text rounded-lg text-capitalize"
                small
                elevation="0"
                @click="generate()"
                :loading="loading"
                :disabled="loading"
                >{{ stringValues.generate[selectedLanguage.code] }}</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import ExpensesComponent from "./ExpensesComponent.vue";
import ReceiptsComponent from "./ReceiptsComponent.vue";
import ReceiptsReportComponent from "./ReceiptsReportComponent.vue";
import ExpensesReportComponent from "./ExpensesReportComponent.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AccountingMaingComponent",
  components: {
    ReceiptsComponent,
    ExpensesComponent,
    ReceiptsReportComponent,
    ExpensesReportComponent,
  },
  computed: {
    ...mapGetters({
      getInfo: "house/GET_INFO",
      getReport: "house/GET_REPORT",
      getFileStatus: "house/GET_FILE_STATUS",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
      getReportDialog: "common/GET_REPORT_DIALOG",
    }),
    info: {
      get: function () {
        return this.getInfo;
      },
    },
    report: {
      get: function () {
        return this.getReport;
      },
    },
    fileStatus: {
      get: function () {
        return this.getFileStatus;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    reportDialog: {
      get: function () {
        return this.getReportDialog;
      },
    },
  },
  data: () => ({
    dateReportFrom: null,
    menuReportFrom: false,
    dateReportTo: null,
    menuReportTo: false,
    loading: false,
    isReportWindow: false,
  }),
  methods: {
    ...mapActions({
      getReportFromApi: "house/GET_REPORT_FROM_API",
      commitMenuBtnWidth: "common/COMMIT_MENU_BTN_WIDTH",
      getExcelReportFromApi: "house/GET_EXCEL_REPORT_FROM_API",
      getPdfReportFromApi: "house/GET_PDF_REPORT_FROM_API",
      commitReportDialog: "common/COMMIT_REPORT_DIALOG",
    }),
    async generate() {
      this.loading = true;

      await this.getReportFromApi({
        residentialComplexId: this.info.data.id,
        from: this.dateReportFrom,
        to: this.dateReportTo,
      });

      if (this.report.succeeded) {
        this.isReportWindow = true;
        this.commitReportDialog(false);
      }

      this.loading = false;
    },
    initMenuBtnWidth() {
      var btnOffsets = document
        .getElementById("menuItem_" + 5)
        .getBoundingClientRect();
      var tableOffsets = document
        .getElementById("accountingTable")
        .getBoundingClientRect();
      let width = tableOffsets.left - btnOffsets.right + btnOffsets.width + 2;
      this.commitMenuBtnWidth(width);
    },
    async generateExcel() {
      this.loading = true;
      await this.getExcelReportFromApi({
        residentialComplexId: this.info.data.id,
        from: this.dateReportFrom,
        to: this.dateReportTo,
      });

      if (this.fileStatus.succeeded) {
        document.location.href = `https://housetest.el-turk.com/ResidentialComplex/Download?Guid=${this.fileStatus.data.guid}&Extension=${this.fileStatus.data.extension}&MimeType=${this.fileStatus.data.mimeType}&Name=${this.fileStatus.data.name}`;
      }

      this.loading = false;
    },
    async generatePdf() {
      this.loading = true;

      await this.getPdfReportFromApi({
        residentialComplexId: this.info.data.id,
        from: this.dateReportFrom,
        to: this.dateReportTo,
      });

      if (this.fileStatus.succeeded) {
        document.location.href = `https://housetest.el-turk.com/ResidentialComplex/Download?Guid=${this.fileStatus.data.guid}&Extension=${this.fileStatus.data.extension}&MimeType=${this.fileStatus.data.mimeType}&Name=${this.fileStatus.data.name}`;
      }

      this.loading = false;
    },
  },
  mounted() {
    this.initMenuBtnWidth();
    window.addEventListener("resize", this.initMenuBtnWidth);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.initMenuBtnWidth);
  },
};
</script>
<style>
.custom-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
  font-size: 0.85em;
}
</style>