import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

import archive from '@/assets/icons/archive.vue';
import el from "../assets/icons/el.vue"
import pencil from "../assets/icons/pencil.vue"
import trash from "../assets/icons/trash.vue"
import excel from "../assets/icons/excel.vue"
import pdf from "../assets/icons/pdf.vue"
import down from "../assets/icons/down.vue"
import elHomes from "../assets/icons/elHomes.vue"
import editAlt from "../assets/icons/editAlt.vue"
import deleteForever from "../assets/icons/deleteForever.vue"
import send from "../assets/icons/send.vue"
import arrowDown from "../assets/icons/arrowDown.vue"
import tenants from "../assets/icons/tenants.vue"
import calculator from "../assets/icons/calculator.vue"
import documentPdf from "../assets/icons/documentPdf.vue"
import edit2 from "../assets/icons/edit2.vue"
import trash2 from "../assets/icons/trash2.vue"
import documentPdf2 from "../assets/icons/documentPdf2.vue"
import download from "../assets/icons/download.vue"
import edit3 from "../assets/icons/edit3.vue"
import sort from "../assets/icons/sort.vue"
import document from "../assets/icons/document.vue"

export default new Vuetify({
    icons: {
        values: {
            customArchive: {
                component: archive
            },
            customEl: {
                component: el
            },
            customPencil: {
                component: pencil
            },
            customTrash: {
                component: trash
            },
            customExcel: {
                component: excel
            },
            customPdf: {
                component: pdf
            },
            customDown: {
                component: down
            },
            customElHomes: {
                component: elHomes
            },
            customEditAlt: {
                component: editAlt
            },
            customDeleteForever: {
                component: deleteForever
            },
            customSend: {
                component: send
            },
            customArrowDown: {
                component: arrowDown
            },
            customTenants: {
                component: tenants
            },
            customCalculator: {
                component: calculator
            },
            customDocumentPdf: {
                component: documentPdf
            },
            customEdit2: {
                component: edit2
            },
            customTrash2: {
                component: trash2
            },
            customDocumentPdf2: {
                component: documentPdf2
            },
            customDownload: {
                component: download
            },
            customEdit3: {
                component: edit3
            },
            customSort: {
                component: sort
            },
            customDocument: {
                component: document
            },
        }
    },
    theme: {
        themes: {
            light: {
                purple: '#621280',
                green: '#4DCC1D'
            },
            dark: {
                purple: '#621280',
                green: '#4DCC1D'
            },
        },

    }
});
