<template>
  <v-card elevation="0" color="grey lighten-4">
    <v-row>
      <v-col cols="12">
        <v-row class="justify-center align-center">
          <v-col cols="10" class="justify-center text-center">
            <v-row>
              <v-col cols="12"> </v-col>
            </v-row>
            <v-text-field
              v-model="email"
              label="e-mail"
              color="purple"
              type="text"
              :rules="[emailRequiredRule(email), emailFormatRule(email)]"
              :error-messages="
                errorMessages
                  .filter((x) =>
                    ['emailRequired', 'emailFormat'].includes(x.ruleName)
                  )
                  .map((x) => x.text)
                  .join()
              "
            >
            </v-text-field>
            <v-text-field
              v-model="password"
              :label="stringValues.password[selectedLanguage.code]"
              color="purple"
              :append-icon="
                passwordShow ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
              "
              :type="passwordShow ? 'text' : 'password'"
              @click:append="passwordShow = !passwordShow"
              :rules="[
                passwordRequiredRule(password),
                passwordMinLengthRule(password),
                passwordMaxLengthRule(password),
              ]"
              :error-messages="
                errorMessages
                  .filter((x) =>
                    ['passwordRequired', 'passwordMin', 'passwordMax'].includes(
                      x.ruleName
                    )
                  )
                  .map((x) => x.text)
                  .join()
              "
            >
            </v-text-field>
            <v-btn
              id="enter-btn"
              width="100%"
              class="mt-2 purple white--text font-weight-regular rounded-lg text-capitalize"
              @click="enter()"
              elevation="0"
              :loading="loading"
              :disabled="loading"
            >
              {{ stringValues.enter[selectedLanguage.code] }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "LoginComponent",
  components: {},
  computed: {
    ...mapGetters({
      getLoginInfo: "account/GET_LOGIN_INFO",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    loginInfo: {
      get: function () {
        return this.getLoginInfo;
      },
      set: function (newValue) {
        this.setLoginInfo(newValue);
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({
    email: null,
    password: null,
    passwordShow: false,
    loading: false,
    modelState: [
      {
        ruleName: "passwordRequired",
        status: false,
        text: null,
      },
      {
        ruleName: "passwordMin",
        status: false,
        text: null,
      },
      {
        ruleName: "passwordMax",
        status: false,
        text: null,
      },
      {
        ruleName: "emailRequired",
        status: false,
        text: null,
      },
      {
        ruleName: "emailFormat",
        status: false,
        text: null,
      },
    ],
    errorMessages: [],
  }),
  methods: {
    ...mapActions({
      loginFromApi: "account/LOGIN_FROM_API",
    }),
    ...mapMutations({
      setLoginInfo: "account/SET_LOGIN_INFO",
      setSnackBar: "common/SET_SNACKBAR",
    }),
    async enter() {
      var validateStatus = this.modelState.every((x) => x.status);
      if (validateStatus) {
        this.loading = true;
        await this.loginFromApi({
          UserName: this.email,
          Password: this.password,
          ApplicationId: "b542a312-7ee8-43be-a8c4-3af3f148d067",
        });

        if (this.loginInfo.succeeded) {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "green",
            text: this.loginInfo.message,
          });

          var data = this.$router.resolve({
            path: "/",
          });
          document.location.href = data.href;
        } else {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "purple",
            text: this.loginInfo.message,
          });
        }
        this.loading = false;
      } else {
        this.errorMessages = this.modelState
          .filter((x) => !x.status)
          .map((x) => ({
            ruleName: x.ruleName,
            text: x.text,
          }));
      }
    },
    passwordRequiredRule(v) {
      let currentRule = this.modelState.find(
        (x) => x.ruleName == "passwordRequired"
      );
      if (v == null || v == undefined) {
        currentRule.status = false;
        currentRule.text = "Enter password";
        return currentRule.text;
      } else {
        let index = this.errorMessages
          .map((x) => x.ruleName)
          .indexOf("passwordRequired");
        if (index != -1) {
          this.errorMessages.splice(index, 1);
        }
        currentRule.status = true;
        return true;
      }
    },
    passwordMinLengthRule(v) {
      let currentRule = this.modelState.find(
        (x) => x.ruleName == "passwordMin"
      );
      if (v != null && v.length < 6) {
        currentRule.status = false;
        currentRule.text = "Minimum password length 6 symbols";
        return currentRule.text;
      } else {
        let index = this.errorMessages
          .map((x) => x.ruleName)
          .indexOf("passwordMin");
        if (index != -1) {
          this.errorMessages.splice(index, 1);
        }
        currentRule.status = true;
        return true;
      }
    },
    passwordMaxLengthRule(v) {
      let currentRule = this.modelState.find(
        (x) => x.ruleName == "passwordMax"
      );
      if (v != null && v.length > 15) {
        currentRule.status = false;
        currentRule.text = "Maximum password length 255 symbols";
        return currentRule.text;
      } else {
        let index = this.errorMessages
          .map((x) => x.ruleName)
          .indexOf("passwordMax");
        if (index != -1) {
          this.errorMessages.splice(index, 1);
        }
        currentRule.status = true;
        return true;
      }
    },
    emailRequiredRule(v) {
      let currentRule = this.modelState.find(
        (x) => x.ruleName == "emailRequired"
      );
      if (v == null || v == undefined) {
        currentRule.status = false;
        currentRule.text = "Enter e-mail";
        return currentRule.text;
      } else {
        let index = this.errorMessages
          .map((x) => x.ruleName)
          .indexOf("emailRequired");
        if (index != -1) {
          this.errorMessages.splice(index, 1);
        }
        currentRule.status = true;
        return true;
      }
    },
    emailFormatRule(v) {
      let currentRule = this.modelState.find(
        (x) => x.ruleName == "emailFormat"
      );
      var emailRegex = /.+@.+\..+/.test(v);
      if (v != null && emailRegex == false) {
        currentRule.status = false;
        currentRule.text = "Incorrect e-mail";
        return currentRule.text;
      } else {
        let index = this.errorMessages
          .map((x) => x.ruleName)
          .indexOf("emailFormat");
        if (index != -1) {
          this.errorMessages.splice(index, 1);
        }
        currentRule.status = true;
        return true;
      }
    },
  },
};
</script>
<style scoped>
#enter-btn {
  width: 160px;
}
</style>