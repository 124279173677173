import { render, staticRenderFns } from "./MessagesComponent.vue?vue&type=template&id=5224c6b5&scoped=true&"
import script from "./MessagesComponent.vue?vue&type=script&lang=js&"
export * from "./MessagesComponent.vue?vue&type=script&lang=js&"
import style0 from "./MessagesComponent.vue?vue&type=style&index=0&id=5224c6b5&prod&lang=css&"
import style1 from "./MessagesComponent.vue?vue&type=style&index=1&id=5224c6b5&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5224c6b5",
  null
  
)

export default component.exports