<template>
  <v-row>
    <v-col cols="12" :class="align">
      <v-menu offset-y style="z-index: 100">
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on">
            <v-icon color="purple">mdi-web</v-icon>
            <span class="text-button" style="font-weight: 600">{{
              selectedLanguage.code
            }}</span>
          </v-btn>
        </template>
        <v-list v-if="languages != null">
          <v-list-item-group>
            <v-list-item
              v-for="item in languages.data"
              :key="item.id"
              @click="selectLanguage(item)"
            >
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "LanguageSelect",
  props: {
    align: {
      type: String,
      default() {
        return "d-flex justify-start align-center";
      },
    },
  },
  computed: {
    ...mapGetters({
      getLanguages: "common/GET_LANGUAGES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    languages: {
      get: function () {
        return this.getLanguages;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({}),
  methods: {
    ...mapActions({
      getLanguagesFromApi: "common/GET_LANGUAGES_FROM_API",
      commitSelectedLanguage: "common/COMMIT_SELECTED_LANGUAGE",
    }),
    selectLanguage(item) {
      this.commitSelectedLanguage(item);
      document.location.reload();
    },
  },
  async created() {
    await this.getLanguagesFromApi();
  },
};
</script>