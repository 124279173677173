<template>
  <v-container>
    <v-card
      elevation="0"
      class="rounded-tr-lg rounded-br-lg rounded-bl-lg px-1 py-1"
      tile
      height="80vh"
      style="border: 1px solid #a438b6"
      id="appliancesTable"
    >
      <v-row class="d-flex align-center" v-if="selectedApartmentNum == null">
        <v-col cols="12">
          <v-data-table
            :headers="headers[selectedLanguage.code]"
            :items="list.data"
            :items-per-page="300"
            class="elevation-0"
            hide-default-footer
            :loading="loading"
            height="69vh"
            dense
          >
            <template slot="progress">
              <v-progress-linear
                color="purple"
                indeterminate
              ></v-progress-linear>
            </template>
            <template v-slot:[`item.user.name`]="{ item }">
              <span
                class="text-caption"
                style="font-weight: 600"
                v-if="item.user != null"
                >{{ item.user.name }}
                <code
                  v-if="item.applianceStatus.id == 1"
                  class="orange white--text py-0"
                  >NEW</code
                >
                <v-icon class="ml-1" small>$customEl</v-icon>
              </span>
              <span class="text-caption" style="font-weight: 600" v-else
                >{{ item.lastName }}
              </span>
            </template>
            <template v-slot:[`item.apartmentNum`]="{ item }">
              <v-btn
                text
                @click="selectApartment(item.apartmentNum, item.bloc)"
                class="text-caption"
              >
                <span class="text-caption font-weight-normal">{{
                  (item.bloc == null ? "" : item.bloc) +
                  (item.bloc == null ||
                  item.apartmentNum == null ||
                  item.bloc == "" ||
                  item.apartmentNum == ""
                    ? ""
                    : "/") +
                  (item.apartmentNum == null ? "" : item.apartmentNum)
                }}</span>
              </v-btn>
            </template>
            <template v-slot:[`item.lastName`]="{ item }">
              <span class="text-caption font-weight-normal">{{
                item.lastName
              }}</span>
            </template>
            <template v-slot:[`item.telephoneNumber`]="{ item }">
              <span class="text-caption" style="font-weight: 600">{{
                item.telephoneNumber
              }}</span>
            </template>
            <template v-slot:[`item.appllicationDate`]="{ item }">
              <span class="text-caption font-weight-normal">{{
                new Date(item.appllicationDate).toLocaleDateString()
              }}</span>
            </template>
            <template v-slot:[`item.applianceStatus.name`]="{ item }">
              <v-btn
                text
                class="text-caption font-weight-normal text-capitalize"
                @click="editItem(item)"
              >
                <span
                  :class="
                    (item.applianceStatus.id == 1
                      ? 'orange--text '
                      : item.applianceStatus.id == 2
                      ? 'green--text'
                      : 'red--text') + ' text-caption font-weight-normal'
                  "
                  >{{ item.applianceStatus.name }}
                </span>
                <v-icon right x-small color="black"> $customEdit2 </v-icon>
              </v-btn>
            </template>
            <template v-slot:[`item.tenantType.name`]="{ item }">
              <span class="text-caption font-weight-normal">{{
                item.tenantType.name
              }}</span>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small color="red" @click="deleteItem(item)">
                $customTrash2
              </v-icon>
            </template>

            <template v-slot:[`header.lastName`]="{ header }">
              <span style="white-space: pre-wrap">{{ header.text }}</span>
            </template>
            <template v-slot:[`header.telephoneNumber`]="{ header }">
              <span style="white-space: pre-wrap">{{ header.text }}</span>
            </template>
            <template v-slot:[`header.appllicationDate`]="{ header }">
              <div class="d-flex justify-center align-center">
                <span style="white-space: pre-wrap">{{ header.text }}</span>
                <v-btn
                  x-small
                  text
                  fab
                  @click="sortBy('appllicationDate', sortOrder)"
                  ><v-icon small>$customSort</v-icon></v-btn
                >
              </div>
            </template>
            <template v-slot:[`header.tenantType.name`]="{ header }">
              <span style="white-space: pre-wrap">{{ header.text }}</span>
            </template>

            <template v-slot:top>
              <v-toolbar flat>
                <v-spacer></v-spacer>
                <v-dialog v-model="addDialog" max-width="500px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="purple"
                      dark
                      class="mb-2 text-capitalize rounded-lg px-6"
                      v-bind="attrs"
                      v-on="on"
                      elevation="0"
                      small
                    >
                      <span style="font-weight: 700">{{
                        stringValues.add[selectedLanguage.code]
                      }}</span>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="text-subtitle-2">{{
                        editedIndexAdd === -1
                          ? stringValues.add[selectedLanguage.code]
                          : stringValues.change[selectedLanguage.code]
                      }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="editedItemAdd.lastName"
                              outlined
                              :label="
                                selectedLanguage.code == 'ru'
                                  ? 'Фамилия по ТАПУ'
                                  : selectedLanguage.code == 2
                                  ? 'Name by TAPU'
                                  : 'TAPU isimi'
                              "
                              color="purple"
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItemAdd.bloc"
                              outlined
                              :label="stringValues.block[selectedLanguage.code]"
                              color="purple"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItemAdd.apartmentNum"
                              outlined
                              :label="
                                stringValues.apartmentNum[selectedLanguage.code]
                              "
                              color="purple"
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="editedItemAdd.telephoneNumber"
                              outlined
                              :label="
                                stringValues.telephone[selectedLanguage.code]
                              "
                              color="purple"
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-btn
                              @click="saveAdd()"
                              elevation="0"
                              class="green white--text text-capitalize text-caption rounded-lg"
                              width="100%"
                            >
                              {{
                                stringValues.createAndApplyAppliance[
                                  selectedLanguage.code
                                ]
                              }}
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="purple" small text @click="closeAddDialog">
                        {{ stringValues.cancel[selectedLanguage.code] }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
          </v-data-table>
          <!-- <v-pagination
            circle
            v-model="list.pageNumber"
            :length="list.totalPages"
            color="purple"
          ></v-pagination> -->
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" class="text-left">
              <v-btn
                small
                text
                class="text-capitalize"
                color="purple"
                @click="
                  selectedApartmentNum = null;
                  selectedBloc = null;
                "
                ><v-icon small>mdi-arrow-left</v-icon>
                {{ stringValues.back[selectedLanguage.code] }}</v-btn
              >
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <ApartmentCard
                :key="selectedApartmentNum"
                :number="selectedApartmentNum"
                :bloc="selectedBloc"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-dialog v-model="dialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="text-subtitle-2">{{
              editedIndex === -1
                ? stringValues.add[selectedLanguage.code]
                : stringValues.change[selectedLanguage.code]
            }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="editedItem.applianceStatus"
                    :items="applianceStatuses.data"
                    item-text="name"
                    item-value="id"
                    hide-details
                    color="purple"
                    dense
                    return-object
                    outlined
                    class="custom-text-field"
                  >
                    <template v-slot:append>
                      <v-icon color="purple" small>$customDown</v-icon>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="purple" small text @click="close">
              {{ stringValues.cancel[selectedLanguage.code] }}
            </v-btn>
            <v-btn color="purple" small text @click="save">
              {{ stringValues.apply[selectedLanguage.code] }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-subtitle-2">{{
            stringValues.deleteSure[selectedLanguage.code]
          }}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="purple" small text @click="closeDelete">{{
              stringValues.cancel[selectedLanguage.code]
            }}</v-btn>
            <v-btn color="purple" small text @click="deleteItemConfirm">{{
              stringValues.apply[selectedLanguage.code]
            }}</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import ApartmentCard from "../Apartments/ApartmentCard.vue";
export default {
  name: "AppliancesListComponent",
  components: {
    ApartmentCard,
  },
  computed: {
    ...mapGetters({
      getList: "house/GET_APPLIANCES",
      getActionStatus: "house/GET_ACTION_STATUS",
      getInfo: "house/GET_INFO",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
      getApplianceStatuses: "house/GET_APPLIANCE_STATUSES",
    }),
    info: {
      get: function () {
        return this.getInfo;
      },
    },
    list: {
      get: function () {
        return this.getList;
      },
      set: function (newVal) {
        this.setList(newVal);
      },
    },
    actionStatus: {
      get: function () {
        return this.getActionStatus;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
    applianceStatuses: {
      get: function () {
        return this.getApplianceStatuses;
      },
    },
  },
  data: () => ({
    headers: {
      ru: [
        {
          text: "Заявитель",
          value: "user.name",
          align: "left",
          sortable: false,
          class: "black--text",
        },
        {
          text: "Номер кв",
          value: "apartmentNum",
          align: "center",
          sortable: false,
          class: "black--text",
        },
        {
          text: "Фамилия \nпо ТАПУ",
          value: "lastName",
          align: "left",
          sortable: false,
          class: "black--text",
        },
        {
          text: "Номер \nтелефона",
          value: "telephoneNumber",
          align: "center",
          sortable: false,
          class: "black--text",
        },
        {
          text: "Дата \nподачи",
          value: "appllicationDate",
          align: "center",
          sortable: false,
          class: "black--text",
        },
        {
          text: "Тип \nЖильца",
          value: "tenantType.name",
          align: "center",
          sortable: false,
          class: "black--text",
        },
        {
          text: "Статус",
          value: "applianceStatus.name",
          align: "center",
          sortable: false,
          class: "black--text",
        },
        {
          text: "",
          value: "actions",
          align: "center",
          sortable: false,
          class: "black--text",
        },
      ],
      en: [
        {
          text: "Applicant",
          value: "user.name",
          align: "left",
          sortable: false,
          class: "black--text",
        },
        {
          text: "Apt Number",
          value: "apartmentNum",
          align: "center",
          sortable: false,
          class: "black--text",
        },
        {
          text: "Name \nby TAPU",
          value: "lastName",
          align: "left",
          sortable: false,
          class: "black--text",
        },
        {
          text: "Tel \nnumber",
          value: "telephoneNumber",
          align: "center",
          sortable: false,
          class: "black--text",
        },
        {
          text: "Application \ndate",
          value: "appllicationDate",
          align: "center",
          sortable: false,
          class: "black--text",
        },
        {
          text: "Tenant \ntype",
          value: "tenantType.name",
          align: "center",
          sortable: false,
          class: "black--text",
        },
        {
          text: "Status",
          value: "applianceStatus.name",
          align: "center",
          sortable: false,
          class: "black--text",
        },
        {
          text: "",
          value: "actions",
          align: "center",
          sortable: false,
          class: "black--text",
        },
      ],
      tr: [
        {
          text: "Başvuran",
          value: "user.name",
          align: "left",
          sortable: false,
          class: "black--text",
        },
        {
          text: "Daire No",
          value: "apartmentNum",
          align: "center",
          sortable: false,
          class: "black--text",
        },
        {
          text: "TAPU \nisimi",
          value: "lastName",
          align: "left",
          sortable: false,
          class: "black--text",
        },
        {
          text: "Tel \nNo.",
          value: "telephoneNumber",
          align: "center",
          sortable: false,
          class: "black--text",
        },
        {
          text: "Başvuru \ntarihi",
          value: "appllicationDate",
          align: "center",
          sortable: false,
          class: "black--text",
        },
        {
          text: "Sakinler \ntipi",
          value: "tenantType.name",
          align: "center",
          sortable: false,
          class: "black--text",
        },
        {
          text: "Durum",
          value: "applianceStatus.name",
          align: "center",
          sortable: false,
          class: "black--text",
        },
        {
          text: "",
          value: "actions",
          align: "center",
          sortable: false,
          class: "black--text",
        },
      ],
    },
    loading: false,
    addDialog: false,
    dialog: false,
    dialogDelete: false,
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    sortOrder: "desc",
    selectedApartmentNum: null,
    selectedBloc: null,
    editedIndexAdd: -1,
    editedItemAdd: {},
  }),
  methods: {
    ...mapActions({
      getListFromApi: "house/GET_APPLICANCES_LIST_BY_RC_ID_FROM_API",
      updateStatusFromApi: "house/UPDATE_APPLIANCE_FROM_API",
      commitMenuBtnWidth: "common/COMMIT_MENU_BTN_WIDTH",
      getApplianceStatusesFromApi: "house/GET_APPLIANCE_STATUSES_FROM_API",
      deleteResidentialComplexUserFromApi:
        "house/DELETE_RESIDENTIAL_COMPLEX_USER_FROM_API",
      updateApplianceFromApi: "house/UPDATE_APPLIANCE_FROM_API",
      addFromApi: "house/ADD_RESIDENTIAL_COMPLEX_USER_FROM_API",
    }),
    ...mapMutations({
      setSnackBar: "common/SET_SNACKBAR",
      setList: "house/SET_APPLIANCES",
    }),
    async getItemsFromApi(
      pageNumber,
      pageSize,
      rcId,
      column = undefined,
      order = undefined
    ) {
      this.loading = true;
      await this.getListFromApi({
        pageNumber: pageNumber,
        pageSize: pageSize,
        residentialComplexId: rcId,
        applianceSort: column,
        sortOrder: order,
      });
      this.loading = false;
    },
    initMenuBtnWidth() {
      var btnOffsets = document
        .getElementById("menuItem_" + 1)
        .getBoundingClientRect();
      var tableOffsets = document
        .getElementById("appliancesTable")
        .getBoundingClientRect();
      let width = tableOffsets.left - btnOffsets.right + btnOffsets.width + 2;
      this.commitMenuBtnWidth(width);
    },
    editItem(item) {
      this.editedIndex = this.list.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.list.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeAddDialog() {
      this.addDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async save() {
      if (this.editedIndex > -1) {
        await this.updateApplianceFromApi({
          residentialComplexId: this.info.data.id,
          userId: this.editedItem.userId,
          applianceStatusId: this.editedItem.applianceStatus.id,
        });
        if (this.actionStatus.succeeded) {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "green",
            text: this.stringValues.ok[this.selectedLanguage.code],
          });
          Object.assign(this.list.data[this.editedIndex], this.editedItem);
        } else {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "purple",
            text: this.stringValues.error[this.selectedLanguage.code],
          });
        }
      }
      this.close();
    },

    async deleteItemConfirm() {
      await this.deleteResidentialComplexUserFromApi({
        data: {
          id: this.editedItem.id,
        },
      });
      if (this.actionStatus.succeeded) {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "green",
          text: this.stringValues.ok[this.selectedLanguage.code],
        });
        this.list.data.splice(this.editedIndex, 1);
      } else {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "purple",
          text: this.stringValues.error[this.selectedLanguage.code],
        });
      }
      this.closeDelete();
    },
    sortBy(column, order) {
      this.sortOrder = order == "asc" ? "desc" : "asc";
      this.getItemsFromApi(1, 300, this.info.data.id, column, this.sortOrder);
    },
    selectApartment(apartmentNum, bloc) {
      this.selectedApartmentNum = apartmentNum;
      this.selectedBloc = bloc;
    },
    uuidv4() {
      return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
    },
    async saveAdd() {
      if (this.editedIndexAdd === -1) {
        await this.addFromApi({
          userId: "G-" + this.uuidv4(),
          residentialComplexId: this.info.data.id,
          lastName: this.editedItemAdd.lastName,
          apartmentNum: this.editedItemAdd.apartmentNum,
          bloc: this.editedItemAdd.bloc,
          telephoneNumber: this.editedItemAdd.telephoneNumber,
        });
        if (this.actionStatus.succeeded) {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "green",
            text: this.actionStatus.data,
          });
          await this.getItemsFromApi(1, 300, this.info.data.id);
        } else {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "purple",
            text: this.actionStatus.data,
          });
        }
      }
      this.closeAddDialog();
    },
  },
  watch: {
    "list.pageNumber": function () {
      this.getItemsFromApi(this.list.pageNumber, 300, this.info.data.id);
    },
    info: function () {
      this.initMenuBtnWidth();
      this.getItemsFromApi(1, 300, this.info.data.id);
      this.getApplianceStatusesFromApi();
    },
  },
  async mounted() {
    this.initMenuBtnWidth();
    window.addEventListener("resize", this.initMenuBtnWidth);
    if (this.info.succeeded) {
      await this.getItemsFromApi(1, 300, this.info.data.id);
      await this.getApplianceStatusesFromApi();
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.initMenuBtnWidth);
  },
};
</script>