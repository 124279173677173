<template>
  <v-container>
    <v-card
      elevation="0"
      class="rounded-lg px-1 py-1"
      height="80vh"
      style="border: 1px solid #4caf50"
      id="webCamerasTable"
    >
      <v-row class="d-flex justify-center align-center" style="height: 40vh">
        <v-col cols="10">
          <v-card elevation="0" @click="selectItem(item.id)" class="rounded-lg">
            <iframe
              width="100%"
              :src="items.find((x) => x.id == 1).src"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
              style="height: 35vh"
            ></iframe>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center align-center" style="height: 40vh">
        <v-col
          cols="12"
          md="5"
          lg="5"
          xl="5"
          v-for="item in items.filter((x) => x.id != 1)"
          :key="item.id"
        >
          <v-card elevation="0" class="rounded-lg">
            <v-card-title class="py-0 px-0">
              <v-spacer />
              <v-btn
                class="purple white--text text-capitalize"
                x-small
                elevation="0"
                @click="selectItem(item.id)"
                >{{ stringValues.select[selectedLanguage.code] }}</v-btn
              >
            </v-card-title>
            <iframe
              width="100%"
              :src="item.src"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
              style="height: 35vh"
            ></iframe>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "WebCamerasComponent",
  computed: {
    ...mapGetters({
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({
    items: [
      {
        id: 1,
        src: "https://www.youtube.com/embed/_NeECL6ntkU?si=PoJJC9WDBSCh5phB&autoplay=1",
      },
      {
        id: 2,
        src: "https://www.youtube.com/embed/h1wly909BYw?si=F15Q-S-dfEo4pPqv&autoplay=1",
      },
      {
        id: 3,
        src: "https://www.youtube.com/embed/5jcrMjEnYu0?si=R2UuafeS-xrRo26W&autoplay=1",
      },
    ],
  }),
  methods: {
    ...mapActions({
      commitMenuBtnWidth: "common/COMMIT_MENU_BTN_WIDTH",
    }),
    initMenuBtnWidth() {
      var btnOffsets = document
        .getElementById("menuItem_" + 4)
        .getBoundingClientRect();
      var tableOffsets = document
        .getElementById("webCamerasTable")
        .getBoundingClientRect();
      let width = tableOffsets.left - btnOffsets.right + btnOffsets.width + 2;
      this.commitMenuBtnWidth(width);
    },
    selectItem(id) {
      this.items.forEach((x) => {
        if (x.id == 1) {
          x.id = id;
          return;
        }

        if (x.id == id) {
          x.id = 1;
        }
      });
    },
  },
  mounted() {
    this.initMenuBtnWidth();
    window.addEventListener("resize", this.initMenuBtnWidth);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.initMenuBtnWidth);
  },
};
</script>
