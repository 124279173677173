<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="12" class="text-left">
        <span class="text-h6" style="font-weight: 600">{{
          stringValues.expenses[selectedLanguage.code]
        }}</span>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" class="text-left">
        <span class="text-subtitle-2" style="font-weight: 600">{{
          stringValues.addExpenses[selectedLanguage.code]
        }}</span>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" md="4" lg="4" xl="4" class="text-left">
        <span class="text-caption">{{
          stringValues.type[selectedLanguage.code]
        }}</span>
        <v-autocomplete
          v-model="selectedCostType"
          :items="costTypesExpenditures.data"
          item-text="name"
          item-value="id"
          hide-details
          color="purple"
          dense
          return-object
          outlined
          class="custom-text-field"
        >
          <template v-slot:append>
            <v-icon color="purple" small>$customDown</v-icon>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" md="4" lg="4" xl="4" class="text-left">
        <span class="text-caption">{{
          stringValues.expenditure[selectedLanguage.code]
        }}</span>
        <v-autocomplete
          v-if="
            selectedCostType != null
              ? selectedCostType.isCustom
                ? false
                : true
              : true
          "
          v-model="selectedExpenditure"
          :items="
            selectedCostType != null
              ? costTypesExpenditures.data.find(
                  (x) => x.id == selectedCostType.id
                ).expenditures
              : null
          "
          item-text="name"
          item-value="id"
          hide-details
          color="purple"
          dense
          return-object
          outlined
          class="custom-text-field"
        >
          <template v-slot:append>
            <v-icon color="purple" small>$customDown</v-icon>
          </template>
        </v-autocomplete>
        <v-text-field
          v-else
          v-model="selectedExpenditureName"
          hide-details
          color="purple"
          dense
          type="text"
          outlined
          class="custom-text-field"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" md="4" lg="4" xl="4" class="text-left">
        <span class="text-caption">{{
          stringValues.amount[selectedLanguage.code]
        }}</span>
        <v-text-field
          v-model="amount"
          hide-details
          color="purple"
          dense
          type="number"
          outlined
          hide-spin-buttons
          class="custom-text-field"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" md="4" lg="4" xl="4" class="text-left">
        <span class="text-caption">{{
          stringValues.paymentDate[selectedLanguage.code]
        }}</span>
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              hide-details
              color="purple"
              dense
              outlined
              readonly
              v-bind="attrs"
              v-on="on"
              class="custom-text-field"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            no-title
            scrollable
            color="purple"
            :locale="
              selectedLanguage != undefined && selectedLanguage != null
                ? selectedLanguage.code
                : 'en'
            "
          >
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" md="4" lg="4" xl="4" class="text-left">
        <span class="text-caption">{{
          stringValues.comment[selectedLanguage.code]
        }}</span>
        <v-textarea
          v-model="comment"
          dense
          color="purple"
          outlined
          height="48px"
          hide-details
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row no-gutters class="mt-2">
      <v-col cols="6" class="text-left">
        <v-btn
          class="purple white--text rounded-lg text-capitalize"
          small
          elevation="0"
          @click="openReportDialog()"
          style="font-weight: 600"
          >{{ stringValues.generateReport[selectedLanguage.code] }}</v-btn
        >
      </v-col>
      <v-col cols="6" class="text-right">
        <v-btn
          class="green white--text rounded-lg text-capitalize"
          small
          elevation="0"
          @click="addExpense()"
          style="font-weight: 600"
          >{{ stringValues.recordConsumption[selectedLanguage.code] }}</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "ExpensesSelect",
  computed: {
    ...mapGetters({
      getLanguages: "common/GET_LANGUAGES",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
      getInfo: "house/GET_INFO",
      getCostTypesExpenditures: "house/GET_COST_TYPES",
      getActionStatus: "house/GET_ACTION_STATUS",
    }),
    languages: {
      get: function () {
        return this.getLanguages;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
    info: {
      get: function () {
        return this.getInfo;
      },
    },
    costTypesExpenditures: {
      get: function () {
        return this.getCostTypesExpenditures;
      },
    },
    actionStatus: {
      get: function () {
        return this.getActionStatus;
      },
    },
  },
  data: () => ({
    selectedCostType: null,
    selectedExpenditure: null,
    date: null,
    menu: false,
    amount: null,
    comment: null,
    selectedExpenditureName: null,
  }),
  methods: {
    ...mapActions({
      getCostTypesExpendituresByRcIdFromApi:
        "house/GET_COST_TYPES_EXPENDITURES_LIST_BY_RC_ID_FROM_API",
      addExpenseFromApi: "house/ADD_EXPENSE_FROM_API",
      commitReportDialog: "common/COMMIT_REPORT_DIALOG",
    }),
    ...mapMutations({
      setSnackBar: "common/SET_SNACKBAR",
      setList: "house/SET_DOCUMENTS",
    }),
    async addExpense() {
      await this.addExpenseFromApi({
        expenditureItemId: this.selectedExpenditure.id,
        residentialComplexId: this.info.data.id,
        amount: this.amount,
        paymentDate: this.date,
        comment:
          this.comment == null || this.comment == undefined ? "" : this.comment,
        expenditureItemName:
          this.selectedExpenditureName == null ||
          this.selectedExpenditureName == undefined
            ? null
            : this.selectedExpenditureName,
      });
      if (this.actionStatus.succeeded) {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "green",
          text: this.stringValues.ok[this.selectedLanguage.code],
        });

        this.selectedExpenditure = null;
        this.amount = null;
        this.date = null;
        this.comment = null;
        this.selectedExpenditureName = null;
        this.selectedCostType = null;
      } else {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "purple",
          text: this.stringValues.error[this.selectedLanguage.code],
        });
      }
    },
    openReportDialog() {
      this.commitReportDialog(true);
    },
  },
  watch: {
    info: function () {
      this.getCostTypesExpendituresByRcIdFromApi({
        residentialComplexId: this.info.data.id,
      });
    },
    selectedCostType: function () {
      if (this.selectedCostType.isCustom) {
        if (this.selectedCostType.expenditures.length > 0) {
          this.selectedExpenditure = this.selectedCostType.expenditures[0];
        }
      }
    },
  },
  async created() {
    if (this.info.succeeded)
      await this.getCostTypesExpendituresByRcIdFromApi({
        residentialComplexId: this.info.data.id,
      });

    if (
      this.costTypesExpenditures.succeeded &&
      this.costTypesExpenditures.data.length > 0
    ) {
      this.selectedCostType = this.costTypesExpenditures.data[0];
      if (this.selectedCostType.expenditures.length > 0) {
        this.selectedExpenditure = this.selectedCostType.expenditures[0];
      }
    }
  },
};
</script>
<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #a438cd;
}
</style>