<template>
  <v-container>
    <v-card
      elevation="0"
      class="rounded-lg px-1 py-1"
      height="80vh"
      style="border: 1px solid #4caf50"
      id="tenantsTable"
    >
      <v-row class="d-flex align-center" v-if="selectedApartmentNum == null">
        <v-col cols="12">
          <v-data-table
            :headers="headers[selectedLanguage.code]"
            :items="list.data"
            :items-per-page="300"
            class="elevation-0"
            hide-default-footer
            :loading="loading"
            height="70vh"
            dense
          >
            <template slot="progress">
              <v-progress-linear
                color="green"
                indeterminate
              ></v-progress-linear>
            </template>
            <template v-slot:[`item.apartmentNum`]="{ item }">
              <v-btn
                text
                @click="selectApartment(item.apartmentNum, item.bloc)"
                class="text-caption"
              >
                <span class="text-caption" style="font-weight: 600">{{
                  (item.bloc == null ? "" : item.bloc) +
                  (item.bloc == null ||
                  item.apartmentNum == null ||
                  item.bloc == "" ||
                  item.apartmentNum == ""
                    ? ""
                    : "/") +
                  (item.apartmentNum == null ? "" : item.apartmentNum)
                }}</span>
              </v-btn>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <span
                class="text-caption font-weight-normal"
                v-if="item.user != null"
                >{{ item.user.firstName + " " + item.user.lastName }}
                <v-icon small>$customEl</v-icon></span
              >
              <span class="text-caption font-weight-normal" v-else>
                {{ item.lastName }}
              </span>
            </template>
            <template v-slot:[`item.user.phoneNumber`]="{ item }">
              <span
                class="text-caption"
                style="font-weight: 600"
                v-if="item.user != null"
                >{{ item.user.phoneNumber }}</span
              >
              <span
                class="text-caption green--text"
                style="font-weight: 600"
                v-else
                >{{ item.telephoneNumber }}</span
              >
            </template>
            <template v-slot:[`item.user.email`]="{ item }">
              <span
                class="text-caption"
                style="font-weight: 600"
                v-if="item.user != null"
                >{{
                  item.user.email.length > 10
                    ? item.user.email.substring(0, 10) + "..."
                    : item.user.email
                }}</span
              >
            </template>
            <template v-slot:[`item.applianceStatus.name`]="{ item }">
              <v-btn
                text
                class="text-caption font-weight-normal text-capitalize"
                @click="editItem(item)"
              >
                <span
                  :class="
                    (item.applianceStatus.id == 1
                      ? 'orange--text '
                      : item.applianceStatus.id == 2
                      ? 'green--text'
                      : 'red--text') + ' text-caption font-weight-normal'
                  "
                  >{{ item.applianceStatus.name }}
                </span>
                <v-icon right x-small color="black"> $customEdit2 </v-icon>
              </v-btn>
            </template>
            <template v-slot:[`item.paid`]="{ item }">
              <span class="text-caption" style="font-weight: 600">{{
                item.paid
              }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                small
                fab
                text
                color="green"
                @click="sendMessage(item)"
                v-if="item.user != null"
              >
                <v-icon>mdi-send-circle</v-icon>
              </v-btn>
            </template>
            <template v-slot:[`item.tenantType.name`]="{ item }">
              <span class="text-caption font-weight-normal">{{
                item.tenantType.name
              }}</span>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-spacer></v-spacer>
                <div v-if="selectedApartmentNum == null" class="mx-2">
                  <v-btn text>
                    <v-icon
                      color="green"
                      @click="generateExcel()"
                      :loading="loading"
                      :disabled="loading"
                      >$customExcel</v-icon
                    >
                  </v-btn>
                  <v-btn text>
                    <v-icon
                      color="red"
                      @click="generatePdf()"
                      :loading="loading"
                      :disabled="loading"
                      >$customPdf</v-icon
                    >
                  </v-btn>
                </div>

                <v-dialog v-model="addDialog" max-width="500px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="purple"
                      dark
                      class="mx-2 text-capitalize rounded-lg px-6"
                      v-bind="attrs"
                      v-on="on"
                      elevation="0"
                      small
                    >
                      <span style="font-weight: 700">{{
                        stringValues.add[selectedLanguage.code]
                      }}</span>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="text-subtitle-2">{{
                        editedIndexAdd === -1
                          ? stringValues.add[selectedLanguage.code]
                          : stringValues.change[selectedLanguage.code]
                      }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="editedItemAdd.lastName"
                              outlined
                              :label="
                                selectedLanguage.code == 'ru'
                                  ? 'Фамилия по ТАПУ'
                                  : selectedLanguage.code == 2
                                  ? 'Name by TAPU'
                                  : 'TAPU isimi'
                              "
                              color="purple"
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItemAdd.bloc"
                              outlined
                              :label="stringValues.block[selectedLanguage.code]"
                              color="purple"
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6">
                            <v-text-field
                              v-model="editedItemAdd.apartmentNum"
                              outlined
                              :label="
                                stringValues.apartmentNum[selectedLanguage.code]
                              "
                              color="purple"
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="editedItemAdd.telephoneNumber"
                              outlined
                              :label="
                                stringValues.telephone[selectedLanguage.code]
                              "
                              color="purple"
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-btn
                              @click="saveAdd()"
                              elevation="0"
                              class="green white--text text-capitalize text-caption rounded-lg"
                              width="100%"
                            >
                              {{
                                stringValues.createAndApplyAppliance[
                                  selectedLanguage.code
                                ]
                              }}
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="purple" small text @click="closeAddDialog">
                        {{ stringValues.cancel[selectedLanguage.code] }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
          </v-data-table>
          <!-- <v-pagination
            circle
            v-model="list.pageNumber"
            :length="list.totalPages"
            color="purple"
          ></v-pagination> -->
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" class="text-left">
              <v-btn
                small
                text
                class="text-capitalize"
                color="purple"
                @click="
                  selectedApartmentNum = null;
                  selectedBloc = null;
                "
                ><v-icon small>mdi-arrow-left</v-icon>
                {{ stringValues.back[selectedLanguage.code] }}</v-btn
              >
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <ApartmentCard
                :key="selectedApartmentNum"
                :number="selectedApartmentNum"
                :bloc="selectedBloc"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-subtitle-2">{{
            editedIndex === -1
              ? stringValues.add[selectedLanguage.code]
              : stringValues.change[selectedLanguage.code]
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  v-model="editedItem.applianceStatus"
                  :items="applianceStatuses.data"
                  item-text="name"
                  item-value="id"
                  hide-details
                  color="purple"
                  dense
                  return-object
                  outlined
                  class="custom-text-field"
                >
                  <template v-slot:append>
                    <v-icon color="purple" small>$customDown</v-icon>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="purple" small text @click="close">
            {{ stringValues.cancel[selectedLanguage.code] }}
          </v-btn>
          <v-btn color="purple" small text @click="save">
            {{ stringValues.apply[selectedLanguage.code] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import ApartmentCard from "../Apartments/ApartmentCard.vue";
export default {
  name: "TenantsListComponent",
  components: {
    ApartmentCard,
  },
  computed: {
    ...mapGetters({
      getList: "house/GET_TENANTS",
      getInfo: "house/GET_INFO",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
      getApplianceStatuses: "house/GET_APPLIANCE_STATUSES",
      getActionStatus: "house/GET_ACTION_STATUS",
      getFileStatus: "house/GET_FILE_STATUS",
    }),
    info: {
      get: function () {
        return this.getInfo;
      },
    },
    list: {
      get: function () {
        return this.getList;
      },
      set: function (newVal) {
        this.setList(newVal);
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
    applianceStatuses: {
      get: function () {
        return this.getApplianceStatuses;
      },
    },
    actionStatus: {
      get: function () {
        return this.getActionStatus;
      },
    },
    fileStatus: {
      get: function () {
        return this.getFileStatus;
      },
    },
  },
  data: () => ({
    headers: {
      ru: [
        {
          text: "Номер кв",
          value: "apartmentNum",
          align: "center",
          sortable: false,
          class: "black--text",
        },
        {
          text: "Имя жильца",
          value: "name",
          align: "left",
          sortable: false,
          class: "black--text",
        },
        {
          text: "Тип Жильца",
          value: "tenantType.name",
          align: "center",
          sortable: false,
          class: "black--text",
        },
        {
          text: "Номер телефона",
          value: "user.phoneNumber",
          align: "center",
          sortable: false,
          class: "black--text",
        },
        {
          text: "e-mail",
          value: "user.email",
          align: "center",
          sortable: false,
          class: "black--text",
        },
        {
          text: "Статус",
          value: "applianceStatus.name",
          align: "center",
          sortable: false,
          class: "black--text",
        },
        {
          text: "Оплачено",
          value: "paid",
          align: "center",
          sortable: false,
          class: "black--text",
        },
        {
          text: "",
          value: "actions",
          align: "center",
          sortable: false,
          class: "black--text",
        },
      ],
      en: [
        {
          text: "Apt Number",
          value: "apartmentNum",
          align: "center",
          sortable: false,
          class: "black--text",
        },
        {
          text: "Resident's name",
          value: "name",
          align: "left",
          sortable: false,
          class: "black--text",
        },
        {
          text: "Tenant type",
          value: "tenantType.name",
          align: "center",
          sortable: false,
          class: "black--text",
        },
        {
          text: "Tel Number",
          value: "user.phoneNumber",
          align: "center",
          sortable: false,
          class: "black--text",
        },
        {
          text: "e-mail",
          value: "user.email",
          align: "center",
          sortable: false,
          class: "black--text",
        },
        {
          text: "Status",
          value: "applianceStatus.name",
          align: "center",
          sortable: false,
          class: "black--text",
        },
        {
          text: "Paid",
          value: "paid",
          align: "center",
          sortable: false,
          class: "black--text",
        },
        {
          text: "",
          value: "actions",
          align: "center",
          sortable: false,
          class: "black--text",
        },
      ],
      tr: [
        {
          text: "Apartman num",
          value: "apartmentNum",
          align: "center",
          sortable: false,
          class: "black--text",
        },
        {
          text: "Sakinin adı",
          value: "name",
          align: "left",
          sortable: false,
          class: "black--text",
        },
        {
          text: "Sakinler tipi",
          value: "tenantType.name",
          align: "center",
          sortable: false,
          class: "black--text",
        },
        {
          text: "Tel No.",
          value: "user.phoneNumber",
          align: "center",
          sortable: false,
          class: "black--text",
        },
        {
          text: "e-mail",
          value: "user.email",
          align: "center",
          sortable: false,
          class: "black--text",
        },
        {
          text: "Durum",
          value: "applianceStatus.name",
          align: "center",
          sortable: false,
          class: "black--text",
        },
        {
          text: "Ödemeli",
          value: "paid",
          align: "center",
          sortable: false,
          class: "black--text",
        },
        {
          text: "",
          value: "actions",
          align: "center",
          sortable: false,
          class: "black--text",
        },
      ],
    },
    loading: false,
    dialog: false,
    addDialog: false,
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    selectedApartmentNum: null,
    selectedBloc: null,
    editedIndexAdd: -1,
    editedItemAdd: {},
  }),
  methods: {
    ...mapActions({
      getListFromApi: "house/GET_TENANTS_LIST_BY_RC_ID_FROM_API",
      commitSelectedDialog: "chat/COMMIT_SELECTED_DIALOG",
      selectMenuItemId: "house/SELECT_MENU_ITEM_ID",
      commitTenantsPageStatus: "chat/COMMIT_TENANTS_PAGE_STATUS",
      commitMenuBtnWidth: "common/COMMIT_MENU_BTN_WIDTH",
      getApplianceStatusesFromApi: "house/GET_APPLIANCE_STATUSES_FROM_API",
      updateApplianceFromApi: "house/UPDATE_APPLIANCE_FROM_API",
      getExcelReportFromApi: "house/GET_TENANTS_EXCEL_REPORT_FROM_API",
      getPdfReportFromApi: "house/GET_TENANTS_PDF_REPORT_FROM_API",
      addFromApi: "house/ADD_RESIDENTIAL_COMPLEX_USER_FROM_API",
    }),
    ...mapMutations({
      setSnackBar: "common/SET_SNACKBAR",
      setList: "house/SET_TENANTS",
    }),
    async getItemsFromApi(pageNumber, pageSize, rcId) {
      this.loading = true;
      await this.getListFromApi({
        pageNumber: pageNumber,
        pageSize: pageSize,
        residentialComplexId: rcId,
      });
      this.loading = false;
    },
    sendMessage(item) {
      this.commitTenantsPageStatus(true);
      this.selectMenuItemId(7);

      var selectedDialogItem = {
        companionId: item.userId,
        name: item.user.firstName + " " + item.user.lastName,
        imageLink: item.user.imageLink,
        isFromMe: true,
        timeOfMessage: new Date(),
        unreadMsgs: 0,
        rcId: item.residentialComplexId,
        residentialComplex: {
          id: item.residentialComplexId,
          name: this.info.data.name,
          userId: item.userId,
          apartmentNum: item.apartmentNum,
        },
      };
      this.commitSelectedDialog(selectedDialogItem);
    },
    initMenuBtnWidth() {
      var btnOffsets = document
        .getElementById("menuItem_" + 2)
        .getBoundingClientRect();
      var tableOffsets = document
        .getElementById("tenantsTable")
        .getBoundingClientRect();
      let width = tableOffsets.left - btnOffsets.right + btnOffsets.width + 2;
      this.commitMenuBtnWidth(width);
    },
    editItem(item) {
      this.editedIndex = this.list.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async save() {
      if (this.editedIndex > -1) {
        await this.updateApplianceFromApi({
          residentialComplexId: this.info.data.id,
          userId: this.editedItem.userId,
          applianceStatusId: this.editedItem.applianceStatus.id,
        });
        if (this.actionStatus.succeeded) {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "green",
            text: this.stringValues.ok[this.selectedLanguage.code],
          });
          Object.assign(this.list.data[this.editedIndex], this.editedItem);
        } else {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "purple",
            text: this.stringValues.error[this.selectedLanguage.code],
          });
        }
      }
      this.close();
    },
    selectApartment(apartmentNum, bloc) {
      this.selectedApartmentNum = apartmentNum;
      this.selectedBloc = bloc;
    },
    async generateExcel() {
      this.loading = true;
      await this.getExcelReportFromApi({
        residentialComplexId: this.info.data.id,
      });

      if (this.fileStatus.succeeded) {
        document.location.href = `https://housetest.el-turk.com/ResidentialComplex/Download?Guid=${this.fileStatus.data.guid}&Extension=${this.fileStatus.data.extension}&MimeType=${this.fileStatus.data.mimeType}&Name=${this.fileStatus.data.name}`;
      }

      this.loading = false;
    },
    async generatePdf() {
      this.loading = true;

      await this.getPdfReportFromApi({
        residentialComplexId: this.info.data.id,
      });

      if (this.fileStatus.succeeded) {
        document.location.href = `https://housetest.el-turk.com/ResidentialComplex/Download?Guid=${this.fileStatus.data.guid}&Extension=${this.fileStatus.data.extension}&MimeType=${this.fileStatus.data.mimeType}&Name=${this.fileStatus.data.name}`;
      }

      this.loading = false;
    },
    closeAddDialog() {
      this.addDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    uuidv4() {
      return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
    },
    async saveAdd() {
      if (this.editedIndexAdd === -1) {
        await this.addFromApi({
          userId: "G-" + this.uuidv4(),
          residentialComplexId: this.info.data.id,
          lastName: this.editedItemAdd.lastName,
          apartmentNum: this.editedItemAdd.apartmentNum,
          bloc: this.editedItemAdd.bloc,
          telephoneNumber: this.editedItemAdd.telephoneNumber,
        });
        if (this.actionStatus.succeeded) {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "green",
            text: this.actionStatus.data,
          });
          await this.getItemsFromApi(1, 300, this.info.data.id);
        } else {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "purple",
            text: this.actionStatus.data,
          });
        }
      }
      this.closeAddDialog();
    },
  },
  watch: {
    "list.pageNumber": function () {
      this.getItemsFromApi(this.list.pageNumber, 300, this.info.data.id);
    },
    info: function () {
      this.initMenuBtnWidth();
      this.getItemsFromApi(1, 300, this.info.data.id);
      this.getApplianceStatusesFromApi();
    },
  },
  async mounted() {
    this.initMenuBtnWidth();
    window.addEventListener("resize", this.initMenuBtnWidth);
    if (this.info.succeeded) {
      await this.getItemsFromApi(1, 300, this.info.data.id);
      this.getApplianceStatusesFromApi();
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.initMenuBtnWidth);
  },
};
</script>
