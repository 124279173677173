import Vue from 'vue'
import Vuex from 'vuex'
import common from './common'
import account from './account'
import house from './house'
import chat from './chat'
import hub from './hub'
import translate from './translate'
import collector from './collector'
Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        common,
        account,
        house,
        chat,
        hub,
        translate,
        collector
    }
})
