export default function (instance) {
    return {
        GetAllLanguages() {
            return instance.get('/Language/GetAll');
        },
        GetResidentialComplexesByAdministratorId() {
            return instance.get('/ResidentialComplex/GetByAdministratorId');
        },
        GetById(payload) {
            return instance.get(`/ResidentialComplex/GetById?id=${payload.id}`);
        },
        GetPagedNewsByUserIdAndResidentialComplexId(payload) {
            return instance.get(`/Administrator/GetPagedNewsByUserIdAndResidentialComplex?PageNumber=${payload.pageNumber}&PageSize=${payload.pageSize}&ResidentialComplexId=${payload.residentialComplexId}`);
        },
        UpdateNews(payload) {
            return instance.put(`/Administrator/UpdateNews`, payload);
        },
        DeleteNews(payload) {
            return instance.delete(`/Administrator/DeleteNews`, payload);
        },
        AddNews(payload) {
            return instance.post(`/Administrator/AddNews`, payload);
        },
        GetPagedDocumentsByUserIdAndResidentialComplexId(payload) {
            return instance.get(`/Administrator/GetPagedDocumentByUserIdAndResidentialComplex?PageNumber=${payload.pageNumber}&PageSize=${payload.pageSize}&ResidentialComplexId=${payload.residentialComplexId}`);
        },
        UpdateDocuments(payload) {
            return instance.put(`/Administrator/UpdateDocument`, payload);
        },
        DeleteDocuments(payload) {
            return instance.delete(`/Administrator/DeleteDocument`, payload);
        },
        AddDocuments(payload) {
            return instance.post(`/Administrator/AddDocument`, payload);
        },
        GetPagedApplianceByUserIdAndResidentialComplexId(payload) {
            if (payload.applianceSort == undefined && payload.sortOrder == undefined) {
                return instance.get(`/Administrator/GetPagedApplianceByUserIdAndResidentialComplex?PageNumber=${payload.pageNumber}&PageSize=${payload.pageSize}&ResidentialComplexId=${payload.residentialComplexId}&ApplianceSort=Id&SortOrder=Desc`);
            }
            else {
                return instance.get(`/Administrator/GetPagedApplianceByUserIdAndResidentialComplex?PageNumber=${payload.pageNumber}&PageSize=${payload.pageSize}&ResidentialComplexId=${payload.residentialComplexId}&ApplianceSort=${payload.applianceSort}&SortOrder=${payload.sortOrder}`);
            }
        },
        UpdateAppliance(payload) {
            return instance.put(`/Administrator/UpdateAppliance`, payload);
        },
        GetPagedTenantsByResidentialComplexId(payload) {
            return instance.get(`/ResidentialComplex/GetPagedTenantsByResidentialComplexId?PageNumber=${payload.pageNumber}&PageSize=${payload.pageSize}&ResidentialComplexId=${payload.residentialComplexId}`);
        },
        GetApartmentsByResidentialComplexId(payload) {
            return instance.get(`/ResidentialComplex/GetApartmentsByResidentialComplexId?ResidentialComplexId=${payload.residentialComplexId}`);
        },
        GetPaymentTypes() {
            return instance.get(`/ResidentialComplex/GetPaymentTypes`);
        },
        GetCostTypesExpendituresByResidentialComplexId(payload) {
            return instance.get(`/ResidentialComplex/GetCostTypesExpendituresByResidentialComplexId?ResidentialComplexId=${payload.residentialComplexId}`);
        },
        AddPayment(payload) {
            return instance.post(`/ResidentialComplex/AddPayment`, payload);
        },
        AddExpense(payload) {
            return instance.post(`/ResidentialComplex/AddExpense`, payload);
        },
        GetAccountingReportByResidentialComplexId(payload) {
            return instance.get(`/ResidentialComplex/GetAccountingReportByResidentialComplexId?ResidentialComplexId=${payload.residentialComplexId}&from=${payload.from}&to=${payload.to}`);
        },
        UpdatePayment(payload) {
            return instance.put(`/ResidentialComplex/UpdatePayment`, payload);
        },
        DeletePayment(payload) {
            return instance.delete(`/ResidentialComplex/DeletePayment`, payload);
        },
        UpdateExpense(payload) {
            return instance.put(`/ResidentialComplex/UpdateExpense`, payload);
        },
        DeleteExpense(payload) {
            return instance.delete(`/ResidentialComplex/DeleteExpense`, payload);
        },
        GetExcelAccountingReportByResidentialComplexId(payload) {
            return instance.get(`/ResidentialComplex/GetExcelAccountingReportByResidentialComplexId?ResidentialComplexId=${payload.residentialComplexId}&from=${payload.from}&to=${payload.to}`);
        },
        GetPdfAccountingReportByResidentialComplexId(payload) {
            return instance.get(`/ResidentialComplex/GetPdfAccountingReportByResidentialComplexId?ResidentialComplexId=${payload.residentialComplexId}&from=${payload.from}&to=${payload.to}`);
        },
        AddDocumentToCdn(payload) {
            return instance.put(`/ResidentialComplex/AddDocumentToCdn`, payload);
        },
        GetHomeInfo(payload) {
            return instance.get(`/ResidentialComplex/GetHomeInfo?ResidentialComplexId=${payload.residentialComplexId}`);
        },
        GetApplianceStatuses() {
            return instance.get(`/ResidentialComplex/GetApplianceStatuses`);
        },
        DeleteResidentialComplexUser(payload) {
            return instance.delete(`/Administrator/DeleteResidentialComplexUser`, payload);
        },
        GetPagedArchivesByResidentialComplexId(payload) {
            return instance.get(`/ResidentialComplex/GetPagedResidentialComplexArchives?PageNumber=${payload.pageNumber}&PageSize=${payload.pageSize}&ResidentialComplexId=${payload.residentialComplexId}`);
        },
        DeleteArchive(payload) {
            return instance.delete(`/ResidentialComplex/DeleteResidentialComplexArchive?archiveId=${payload.archiveId}`);
        },
        AddArchive(payload) {
            return instance.put(`/ResidentialComplex/AddResidentialComplexArchive`, payload);
        },
        GetApartment(payload) {
            return instance.get(`/ResidentialComplex/GetApartment?ResidentialComplexId=${payload.residentialComplexId}&ApartmentNum=${payload.apartmentNum}&Bloc=${payload.bloc}`);
        },
        UpdateResidentialComplexApartmentDebt(payload) {
            return instance.put(`/ResidentialComplex/UpdateResidentialComplexApartmentDebt`, payload);
        },
        UpdateResidentialComplexApartmentNote(payload) {
            return instance.put(`/ResidentialComplex/UpdateResidentialComplexApartmentNote`, payload);
        },
        AddApartmentAttachment(payload) {
            return instance.put(`/ResidentialComplex/AddResidentialComplexApartmentAttachments`, payload);
        },
        DeleteApartmentAttachment(payload) {
            return instance.delete(`/ResidentialComplex/DeleteUserResidentialComplexAttachment?attachmentId=${payload.attachmentId}`);
        },
        GetExcelTenantsReportByResidentialComplexId(payload) {
            return instance.get(`/ResidentialComplex/GetExcelTenantsReportByResidentialComplexId?ResidentialComplexId=${payload.residentialComplexId}`);
        },
        GetPdfTenantsReportByResidentialComplexId(payload) {
            return instance.get(`/ResidentialComplex/GetPdfTenantsReportByResidentialComplexId?ResidentialComplexId=${payload.residentialComplexId}`);
        },
        UpdateResidentialComplexApartmentStatus(payload) {
            return instance.put(`/ResidentialComplex/UpdateResidentialComplexApartmentStatus`, payload);
        },
        AddResidentialComplexUser(payload) {
            return instance.post(`/Administrator/AddResidentialComplexUser`, payload);
        },
    }
}
