<template>
  <v-container>
    <v-card
      elevation="0"
      class="rounded-lg px-1 py-1"
      height="80vh"
      style="border: 1px solid #a438b6"
      id="newsTable"
    >
      <v-row class="d-flex align-center">
        <v-col cols="12">
          <v-data-table
            :headers="headers[selectedLanguage.code]"
            :items="list.data"
            :items-per-page="20"
            class="elevation-0"
            hide-default-footer
            :loading="loading"
            fixed-header
            height="60vh"
            dense
          >
            <template slot="progress">
              <v-progress-linear
                color="purple"
                indeterminate
              ></v-progress-linear>
            </template>
            <template v-slot:[`item.header`]="{ item }">
              <span class="text-caption font-weight-normal">{{
                item.header
              }}</span>
            </template>
            <template v-slot:[`item.createdDate`]="{ item }">
              <span class="text-caption font-weight-normal">{{
                new Date(item.createdDate).toLocaleDateString()
              }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" color="purple" @click="editItem(item)">
                $customEditAlt
              </v-icon>
              <v-icon small color="red" @click="deleteItem(item)">
                $customTrash
              </v-icon>
            </template>

            <template v-slot:[`header.header`]="{ header }">
              <div class="text-center">
                <span>{{ header.text }}</span>
              </div>
            </template>

            <template v-slot:top>
              <v-toolbar flat>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="500px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="purple"
                      dark
                      class="mb-2 text-capitalize rounded-lg px-6"
                      v-bind="attrs"
                      v-on="on"
                      elevation="0"
                      small
                    >
                      <span style="font-weight: 700">{{
                        stringValues.add[selectedLanguage.code]
                      }}</span>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="text-subtitle-2">{{
                        editedIndex === -1
                          ? stringValues.add[selectedLanguage.code]
                          : stringValues.change[selectedLanguage.code]
                      }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="editedItem.header"
                              :label="
                                stringValues.header[selectedLanguage.code]
                              "
                              color="purple"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-textarea
                              v-model="editedItem.description"
                              :label="
                                stringValues.description[selectedLanguage.code]
                              "
                              color="purple"
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="purple" small text @click="close">
                        {{ stringValues.cancel[selectedLanguage.code] }}
                      </v-btn>
                      <v-btn color="purple" small text @click="save">
                        {{ stringValues.apply[selectedLanguage.code] }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title class="text-subtitle-2">{{
                      stringValues.deleteSure[selectedLanguage.code]
                    }}</v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="purple" small text @click="closeDelete">{{
                        stringValues.cancel[selectedLanguage.code]
                      }}</v-btn>
                      <v-btn
                        color="purple"
                        small
                        text
                        @click="deleteItemConfirm"
                        >{{ stringValues.apply[selectedLanguage.code] }}</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
          </v-data-table>
          <v-pagination
            circle
            v-model="list.pageNumber"
            :length="list.totalPages"
            color="purple"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "NewsListComponent",
  computed: {
    ...mapGetters({
      getList: "house/GET_NEWS",
      getActionStatus: "house/GET_ACTION_STATUS",
      getInfo: "house/GET_INFO",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    info: {
      get: function () {
        return this.getInfo;
      },
    },
    list: {
      get: function () {
        return this.getList;
      },
      set: function (newVal) {
        this.setList(newVal);
      },
    },
    actionStatus: {
      get: function () {
        return this.getActionStatus;
      },
    },
    formTitle() {
      return this.editedIndex === -1 ? "Добавить" : "Изменить";
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({
    loading: false,
    headers: {
      ru: [
        {
          text: "Заголовок",
          value: "header",
          align: "left",
          sortable: false,
          class: "black--text",
        },
        {
          text: "Дата создания",
          value: "createdDate",
          align: "center",
          sortable: false,
          class: "black--text",
        },
        {
          text: "Действия",
          value: "actions",
          align: "center",
          sortable: false,
          class: "black--text",
        },
      ],
      en: [
        {
          text: "Title",
          value: "header",
          align: "left",
          sortable: false,
          class: "black--text",
        },
        {
          text: "Date of issue",
          value: "createdDate",
          align: "center",
          sortable: false,
          class: "black--text",
        },
        {
          text: "Actions",
          value: "actions",
          align: "center",
          sortable: false,
          class: "black--text",
        },
      ],
      tr: [
        {
          text: "Başlık",
          value: "header",
          align: "left",
          sortable: false,
          class: "black--text",
        },
        {
          text: "Oluşturulma tarihi",
          value: "createdDate",
          align: "center",
          sortable: false,
          class: "black--text",
        },
        {
          text: "Eylemler",
          value: "actions",
          align: "center",
          sortable: false,
          class: "black--text",
        },
      ],
    },
    dialog: false,
    dialogDelete: false,
    editedItem: {
      id: 0,
      header: "",
      description: "",
    },
    defaultItem: {
      id: 0,
      header: "",
      description: "",
    },
    editedIndex: -1,
  }),
  methods: {
    ...mapActions({
      getListFromApi: "house/GET_NEWS_LIST_BY_RC_ID_FROM_API",
      updateFromApi: "house/UPDATE_NEWS_FROM_API",
      addFromApi: "house/ADD_NEWS_FROM_API",
      deleteFromApi: "house/DELETE_NEWS_FROM_API",
      commitMenuBtnWidth: "common/COMMIT_MENU_BTN_WIDTH",
    }),
    ...mapMutations({
      setSnackBar: "common/SET_SNACKBAR",
      setList: "house/SET_NEWS",
    }),
    async getItemsFromApi(pageNumber, pageSize, rcId) {
      this.loading = true;
      await this.getListFromApi({
        pageNumber: pageNumber,
        pageSize: pageSize,
        residentialComplexId: rcId,
      });
      this.loading = false;
    },
    editItem(item) {
      this.editedIndex = this.list.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.list.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      await this.deleteFromApi({
        data: {
          newsId: this.editedItem.id,
        },
      });
      if (this.actionStatus.succeeded) {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "green",
          text: this.stringValues.ok[this.selectedLanguage.code],
        });
        this.list.data.splice(this.editedIndex, 1);
      } else {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "purple",
          text: this.stringValues.error[this.selectedLanguage.code],
        });
      }
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (this.editedIndex > -1) {
        await this.updateFromApi({
          newsId: this.editedItem.id,
          pointId: this.info.data.id,
          description: this.editedItem.description,
          header: this.editedItem.header,
        });
        if (this.actionStatus.succeeded) {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "green",
            text: this.stringValues.ok[this.selectedLanguage.code],
          });
          Object.assign(this.list.data[this.editedIndex], this.editedItem);
        } else {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "purple",
            text: this.stringValues.error[this.selectedLanguage.code],
          });
        }
      } else {
        await this.addFromApi({
          pointId: this.info.data.id,
          description: this.editedItem.description,
          header: this.editedItem.header,
        });
        if (this.actionStatus.succeeded) {
          this.editedItem.createdDate = new Date();
          this.list.data.unshift(this.editedItem);
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "green",
            text: this.stringValues.ok[this.selectedLanguage.code],
          });
        } else {
          this.setSnackBar({
            show: true,
            timeout: 3000,
            color: "purple",
            text: this.stringValues.error[this.selectedLanguage.code],
          });
        }
      }
      this.close();
    },
    initMenuBtnWidth() {
      var btnOffsets = document
        .getElementById("menuItem_" + 3)
        .getBoundingClientRect();
      var tableOffsets = document
        .getElementById("newsTable")
        .getBoundingClientRect();
      let width = tableOffsets.left - btnOffsets.right + btnOffsets.width + 2;
      this.commitMenuBtnWidth(width);
    },
  },
  watch: {
    "list.pageNumber": function () {
      this.getItemsFromApi(this.list.pageNumber, 20, this.info.data.id);
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    info: function () {
      this.initMenuBtnWidth();
      this.getItemsFromApi(1, 20, this.info.data.id);
    },
  },
  async mounted() {
    this.initMenuBtnWidth();
    window.addEventListener("resize", this.initMenuBtnWidth);
    if (this.info.succeeded)
      await this.getItemsFromApi(1, 20, this.info.data.id);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.initMenuBtnWidth);
  },
};
</script>