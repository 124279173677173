<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="d-flex justify-center align-center">
        <v-card elevation="0" v-if="info.succeeded" class="grey lighten-4">
          <v-carousel
            height="40vh"
            hide-delimiter-background
            hide-delimiters
            v-model="currentImage"
          >
            <v-carousel-item v-for="n in items.length" :key="n">
              <v-img
                lazy-src="https://cdn.el-turk.com/common/lazy-load.png"
                :src="items[n - 1].path"
                class="rounded-xl"
                max-width="100%"
                height="40vh"
              >
              </v-img>
            </v-carousel-item>
          </v-carousel>
          <v-row>
            <v-col cols="12">
              <v-row class="mt-1 d-flex justify-center align-center">
                <v-col cols="3" v-for="n in items.length" :key="n">
                  <v-img
                    lazy-src="https://cdn.el-turk.com/common/lazy-load.png"
                    :src="items[n - 1].path"
                    class="rounded-lg text-right"
                    @click="open(n - 1)"
                    max-width="100%"
                    height="10vh"
                    style="cursor: pointer"
                  >
                    <v-scale-transition>
                      <v-chip
                        style="cursor: pointer"
                        small
                        class="purple white--text mx-1 my-1"
                        @click="deleteImage(n - 1)"
                        >X</v-chip
                      >
                    </v-scale-transition>
                  </v-img>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-file-input
            ref="uploader"
            class="d-none"
            v-model="images"
            accept="image/png, image/jpeg, image/bmp, image/jpg"
            prepend-icon="mdi-camera"
            color="purple"
            counter
            chips
            multiple
            outlined
            dense
          ></v-file-input>
          <v-divider class="my-4" />
          <span style="font-weight: 600" v-if="imageURLs.length > 0"
            >Добавленные изображения</span
          >
          <v-row>
            <v-col cols="12">
              <v-row class="mt-1 d-flex justify-center align-center">
                <v-col cols="3" v-for="n in imageURLs.length" :key="n">
                  <v-img
                    lazy-src="https://cdn.el-turk.com/common/lazy-load.png"
                    :src="imageURLs[n - 1].path"
                    class="rounded-lg text-right"
                    @click="open(n - 1)"
                    max-width="100%"
                    height="10vh"
                    style="cursor: pointer"
                  >
                    <v-scale-transition>
                      <v-chip
                        style="cursor: pointer"
                        small
                        class="purple white--text mx-1 my-1"
                        @click="deleteAddedImage(n - 1)"
                        >X</v-chip
                      >
                    </v-scale-transition>
                  </v-img>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-center">
              <v-btn
                small
                class="purple white--text rounded-lg text-capitalize mx-2"
                @click="addImage()"
                elevation="0"
              >
                <v-icon small left>mdi-file-image-plus-outline</v-icon>
                Добавить изображение
              </v-btn>
              <v-btn
                small
                class="purple white--text rounded-lg text-capitalize mx-2"
                @click="update()"
                :loading="loading"
                :disabled="loading"
                elevation="0"
                >{{ stringValues.apply[selectedLanguage.code] }}</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
        <v-card elevation="0" width="100%" v-else class="grey lighten-4">
          <v-skeleton-loader
            height="40vh"
            width="100%"
            type="card"
          ></v-skeleton-loader>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "ResidentialComplexImagesEditComponent",
  computed: {
    ...mapGetters({
      getInfo: "house/GET_INFO",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
      getActionStatus: "collector/GET_ACTION_STATUS",
    }),
    info: {
      get: function () {
        return this.getInfo;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
    actionStatus: {
      get: function () {
        return this.getActionStatus;
      },
    },
  },
  data: () => ({
    selectedParameters: null,
    loading: false,
    items: null,
    currentImage: null,
    deleteImages: [],
    images: [],
    imageURLs: [],
  }),
  methods: {
    ...mapActions({
      updateFromApi: "collector/UPDATE_FROM_API",
    }),
    ...mapMutations({
      setSnackBar: "common/SET_SNACKBAR",
    }),
    open(n) {
      this.currentImage = n;
    },
    deleteImage(n) {
      this.deleteImages.push(this.items[n].id);
      this.items.splice(n, 1);
    },
    deleteAddedImage(n) {
      this.images.splice(n, 1);
    },
    addImage() {
      this.$refs.uploader.$refs.input.click();
    },
    async imagesToBase64(images) {
      if (
        this.imageURLs == null ||
        this.imageURLs == undefined ||
        this.imageURLs.length == 0
      )
        return;

      let mainImageIndex = this.imageURLs.find((x) => x.isMain).index;

      let result = [];
      for (var i = 0; i < images.length; i++) {
        let file = images[i];
        const getBase64 = (file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
          });
        };
        result.push({
          base64: await getBase64(file),
          index: i,
          isMain: mainImageIndex == i ? true : false,
        });
      }

      return result;
    },
    async update() {
      this.loading = true;
      let model = {
        id: this.info.data.id,
        parameterValues: [],
        addImages: [],
        deleteImages: [],
      };

      if (
        this.images != null &&
        this.images != undefined &&
        this.images.length > 0
      ) {
        let imagesBase64 = await this.imagesToBase64(this.images);
        imagesBase64 = imagesBase64.map((x) => ({
          value: x.base64.substring(x.base64.indexOf(",") + 1),
        }));
        model.addImages = imagesBase64;
      }

      if (
        this.deleteImages != null &&
        this.deleteImages != undefined &&
        this.deleteImages.length > 0
      ) {
        model.deleteImages = this.deleteImages.map((x) => ({
          value: x,
        }));
      }

      await this.updateFromApi(model);
      if (this.actionStatus.succeeded) {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "green",
          text: "OK!",
        });
      } else {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "purple",
          text: "Error!",
        });
      }

      this.loading = false;
    },
  },
  watch: {
    images: function () {
      this.imageURLs = [];
      for (var i = 0; i < this.images.length; i++) {
        this.imageURLs.push({
          index: i,
          path: URL.createObjectURL(this.images[i]),
          isMain: i == 0 ? true : false,
        });
      }
    },
  },
  async created() {
    this.items = this.info.data.images;
  },
};
</script>