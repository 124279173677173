import house from "../api/house/index"

const GetAllLanguages = () => {
    return new Promise((resolve) => {
        resolve(house.house.GetAllLanguages())
    })
}

export default {
    namespaced: true,
    state: {
        snackbar: {
            show: false,
            timeout: 3000,
            color: 'purple',
            text: ''
        },
        location: null,
        languages: null,
        selectedLanguage: null,
        stringValues: null,
        menuBtnWidth: null,
        dialog: {
            show: false,
            fullscreen: false,
            hide_overlay: false,
            transition: "dialog-bottom-transition",
            title: "Заголовок",
            view_id: 0,
            width: 500
        },
        reportDialog: false,
    },
    getters: {
        GET_SNACKBAR(state) {
            return state.snackbar;
        },
        GET_LOCATION(state) {
            return state.location;
        },
        GET_LANGUAGES(state) {
            return state.languages;
        },
        GET_SELECTED_LANGUAGE(state) {
            if (localStorage.getItem('language') != null) {
                var languageObj = JSON.parse(localStorage.getItem('language'))
                languageObj = { data: languageObj };
                state.selectedLanguage = languageObj.data;
            }
            else {
                localStorage.setItem('language', JSON.stringify({ code: 'ru', id: 1, name: 'Русский' }));
                state.selectedLanguage = { code: 'ru', id: 1, name: 'Русский' };
            }
            return state.selectedLanguage;
        },
        GET_STRING_VALUES(state) {
            return state.stringValues;
        },
        GET_MENU_BTN_WIDTH(state) {
            return state.menuBtnWidth;
        },
        GET_DIALOG(state) {
            return state.dialog;
        },
        GET_REPORT_DIALOG(state) {
            return state.reportDialog;
        },
    },
    mutations: {
        SET_SNACKBAR(state, payload) {
            state.snackbar = payload;
        },
        SET_LOCATION(state, payload) {
            state.location = payload;
        },
        SET_LANGUAGES(state, payload) {
            state.languages = payload;
        },
        SET_SELECTED_LANGUAGE(state, payload) {
            localStorage.setItem('language', JSON.stringify(payload));
            state.selectedLanguage = payload;
        },
        SET_STRING_VALUES(state, payload) {
            state.stringValues = payload;
        },
        SET_MENU_BTN_WIDTH(state, payload) {
            state.menuBtnWidth = payload;
        },
        SET_DIALOG(state, payload) {
            state.dialog = payload;
        },
        SET_REPORT_DIALOG(state, payload) {
            state.reportDialog = payload;
        },
    },
    actions: {
        GENERATE_SNACKBAR({ commit }, payload) {
            commit('SET_SNACKBAR', payload);
        },
        GENERATE_LOCATION({ commit }, payload) {
            commit('SET_LOCATION', payload);
        },
        async GET_LANGUAGES_FROM_API({ commit }) {
            await GetAllLanguages().then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_LANGUAGES', data);
                    }
                }
            )
                .catch(() => {
                    commit('SET_LANGUAGES', { succeeded: false });
                })
        },
        COMMIT_SELECTED_LANGUAGE({ commit }, payload) {
            commit('SET_SELECTED_LANGUAGE', payload);
        },
        COMMIT_STRING_VALUES({ commit }, payload) {
            commit('SET_STRING_VALUES', payload);
        },
        COMMIT_MENU_BTN_WIDTH({ commit }, payload) {
            commit('SET_MENU_BTN_WIDTH', payload);
        },
        GENERATE_DIALOG({ commit }, payload) {
            commit('SET_DIALOG', payload);
        },
        COMMIT_REPORT_DIALOG({ commit }, payload) {
            commit('SET_REPORT_DIALOG', payload);
        },
    }
}