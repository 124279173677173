<template>
  <v-container>
    <div v-if="parameters.succeeded" class="my-2">
      <v-row v-for="parameter in selectedParameters.data" :key="parameter.id">
        <v-col cols="6" class="text-center">
          <span
            >{{ parameter.name
            }}{{ parameter.isRequired ? " (обязательно*)" : "" }}:</span
          >
        </v-col>
        <v-col cols="6" class="text-center">
          <div v-if="parameter.isFromCatalog">
            <v-select
              v-model="parameter.selectedValues"
              :items="parameter.parameterCatalogs"
              item-text="name"
              item-value="id"
              :label="stringValues.selectElements[selectedLanguage.code]"
              :multiple="parameter.isMultipleSelect"
              outlined
              dense
              small-chips
              color="purple"
            ></v-select>
          </div>
          <div v-else>
            <v-text-field
              v-model="parameter.selectedValues"
              :label="parameter.name"
              outlined
              dense
              color="purple"
            ></v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="text-center">
          <v-btn
            small
            class="purple white--text rounded-lg text-capitalize"
            @click="update()"
            :loading="loading"
            :disabled="loading"
            elevation="0"
            >{{ stringValues.change[selectedLanguage.code] }}</v-btn
          >
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  name: "ResidentialComplexParametersEditComponent",
  computed: {
    ...mapGetters({
      getParameters: "collector/GET_PARAMETERS",
      getInfo: "house/GET_INFO",
      getActionStatus: "collector/GET_ACTION_STATUS",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    parameters: {
      get: function () {
        return this.getParameters;
      },
    },
    info: {
      get: function () {
        return this.getInfo;
      },
    },
    actionStatus: {
      get: function () {
        return this.getActionStatus;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({
    selectedParameters: null,
    loading: false,
  }),
  methods: {
    ...mapActions({
      getParametersFromApi: "collector/GET_PARAMETERS_FROM_API",
      updateFromApi: "collector/UPDATE_FROM_API",
    }),
    ...mapMutations({
      setSnackBar: "common/SET_SNACKBAR",
    }),
    async update() {
      this.loading = true;

      if (
        this.selectedParameters != null &&
        this.selectedParameters.succeeded
      ) {
        if (this.selectedParameters.data != null) {
          let requiredParametersId = this.selectedParameters.data
            .filter((x) => x.isRequired)
            .map((x) => x.id);

          let formatedCatalogSingleParameters =
            this.selectedParameters.data.filter(
              (x) =>
                x.selectedValues > 0 && !x.isMultipleSelect && x.isFromCatalog
            );
          formatedCatalogSingleParameters = formatedCatalogSingleParameters.map(
            (x) => ({
              parameterId: x.id,
              valueId: x.selectedValues,
              value: "lol",
            })
          );

          let catalogMultipleParameters = this.selectedParameters.data.filter(
            (x) =>
              x.selectedValues.length > 0 &&
              x.isMultipleSelect &&
              x.isFromCatalog
          );
          let formattedCatalogMultipleParameters = [];
          for (var i = 0; i < catalogMultipleParameters.length; i++) {
            let parameterId = catalogMultipleParameters[i].id;
            for (
              var j = 0;
              j < catalogMultipleParameters[i].selectedValues.length;
              j++
            ) {
              formattedCatalogMultipleParameters.push({
                parameterId: parameterId,
                valueId: catalogMultipleParameters[i].selectedValues[j],
                value: "lol",
              });
            }
          }

          let manualParameters = this.selectedParameters.data.filter(
            (x) => !x.isMultipleSelect && !x.isFromCatalog
          );

          manualParameters = manualParameters.map((x) => ({
            parameterId: x.id,
            value:
              typeof x.selectedValues == "string"
                ? x.selectedValues
                : x.selectedValues.join(),
            valueId: 0,
          }));

          let resultParameterValues = [
            ...new Set([
              ...formatedCatalogSingleParameters,
              ...formattedCatalogMultipleParameters,
              ...manualParameters,
            ]),
          ];

          let model = {
            id: this.info.data.id,
            parameterValues: resultParameterValues.filter(
              (x) => x.value.length > 0
            ),
            addImages: [],
            deleteImages: [],
          };

          let resultParametersId = resultParameterValues.map(
            (x) => x.parameterId
          );
          for (var k = 0; k < requiredParametersId.length; k++) {
            let requiredParametersCheckStatus = resultParametersId.some(
              (x) => x == requiredParametersId[k]
            );
            if (!requiredParametersCheckStatus) {
              this.setSnackBar({
                show: true,
                timeout: 3000,
                color: "purple",
                text: "Error!",
              });
              this.loading = false;
              return;
            }
          }

          await this.updateFromApi(model);
          if (this.actionStatus.succeeded) {
            this.setSnackBar({
              show: true,
              timeout: 3000,
              color: "green",
              text: "OK!",
            });
          } else {
            this.setSnackBar({
              show: true,
              timeout: 3000,
              color: "purple",
              text: "Error!",
            });
          }

          this.loading = false;
        }
      }
    },
  },
  async created() {
    await this.getParametersFromApi();
    let selectedParameters = this.parameters;
    if (selectedParameters != null && selectedParameters.data != null) {
      let currentValues = this.info.data.groupedParameterValues;

      let newData = selectedParameters.data.map((z) => ({
        id: z.id,
        isFromCatalog: z.isFromCatalog,
        isMultipleSelect: z.isMultipleSelect,
        isRequired: z.isRequired,
        name: z.name,
        parameterCatalogs: z.parameterCatalogs,
        valueType: z.valueType,
        valueTypeId: z.valueTypeId,
        selectedValues:
          currentValues.find((y) => y.parameterId == z.id) != undefined
            ? z.isFromCatalog
              ? z.isMultipleSelect
                ? currentValues
                    .find((y) => y.parameterId == z.id)
                    .pointParameterValueItemList.map((y) => y.valueId)
                : currentValues
                    .find((y) => y.parameterId == z.id)
                    .pointParameterValueItemList.map((y) => y.valueId)[0]
              : currentValues
                  .find((y) => y.parameterId == z.id)
                  .pointParameterValueItemList.map(
                    (y) => y.parameterCatalog.name
                  )
            : [],
      }));

      selectedParameters.data = newData;
    }

    this.selectedParameters = selectedParameters;
  },
};
</script>
