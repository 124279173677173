import axios from 'axios'


const instance = axios.create({
    baseURL: "https://translate.el-turk.com/"
})

instance.interceptors.response.use(undefined, err => {
    const error = err.response;
    if (error.status === 401 || error.status === 403) {
        localStorage.removeItem('identity');
        window.location.assign('/account/login');
    }
})

export default instance
