<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-row v-for="item in items" :key="item.id" no-gutters>
          <v-col cols="12">
            <v-btn
              v-if="selectedButton != item.id"
              outlined
              :color="item.color"
              class="rounded-lg text-left"
              width="100%"
              @click="select(item.id)"
              :class="selectedButton == item.id ? 'selectedButton' : null"
              :id="'menuItem_' + item.id"
              :style="{
                marginTop: item.id == 1 ? '0px' : '25px',
                minWidth: '180px'
              }"
            >
              <v-icon left color="black">{{ item.icon }}</v-icon>
              <span class="black--text text-capitalize text-subtitle-2">{{
                item.title[selectedLanguage.code]
              }}</span>
              <v-spacer />
            </v-btn>

            <v-btn
              v-else
              outlined
              :color="item.color"
              class="rounded-l-lg text-left"
              :width="getMenuBtnWidth == null ? '100%' : getMenuBtnWidth"
              height="39px"
              tile
              @click="select(item.id)"
              :class="selectedButton == item.id ? 'selectedButton' : null"
              :style="
                item.id == 1
                  ? 'margin-top: 0px !important; ; border-right: none; z-index: 10; -webkit-transition: all 0.2s linear; -moz-transition: all 0.2s linear; -o-transition: all 0.2s linear;transition: all 0.2s linear;'
                  : 'margin-top: 25px !important; border-right: none; z-index: 10; -webkit-transition: all 0.2s linear; -moz-transition: all 0.2s linear; -o-transition: all 0.2s linear;transition: all 0.2s linear;'
              "
              :id="'menuItem_' + item.id"
            >
              <v-icon left color="black">{{ item.icon }}</v-icon>
              <span class="black--text text-capitalize text-subtitle-2">{{
                item.title[selectedLanguage.code]
              }}</span>
              <v-spacer />
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex justify-center">
        <div
          style="
            border: 1px green solid;
            border-radius: 8px;
            width: max-content;
            padding: 10px 10px 10px 10px;
            background-color: white;
          "
          class="text-center"
        >
          <v-icon size="79">$customElHomes</v-icon>
          <div>
            <span style="font-weight: 600; font-size: 16px" class="purple--text"
              >EASY LIFE</span
            >
          </div>
          <div>
            <span style="font-weight: 600; font-size: 21px" class="purple--text"
              >HOMES</span
            >
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ResidentialComplexMenuComponent",
  computed: {
    ...mapGetters({
      getMenuBtnWidth: "common/GET_MENU_BTN_WIDTH",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
    }),
    info: {
      get: function () {
        return this.getInfo;
      },
    },
    list: {
      get: function () {
        return this.getList;
      },
      set: function (newVal) {
        this.setList(newVal);
      },
    },
    menuBtnWidth: {
      get: function () {
        return this.getMenuBtnWidth;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
  },
  data: () => ({
    items: [
      {
        id: 1,
        title: {
          ru: "Заявки",
          en: "Applications",
          tr: "Başvurular",
        },
        icon: "mdi-list-box-outline",
        color: "purple",
      },
      {
        id: 2,
        title: {
          ru: "Список жильцов",
          en: "List of tenants",
          tr: "Sakinlerin Listesi",
        },
        icon: "$customTenants",
        color: "green",
      },
      {
        id: 3,
        title: {
          ru: "Новости",
          en: "News",
          tr: "Haberler",
        },
        icon: "mdi-message-processing-outline",
        color: "purple",
      },
      // {
      //   id: 4,
      //   title: {
      //     ru: "Видеокамеры",
      //     en: "Video cameras",
      //     tr: "Video kameralar",
      //   },
      //   icon: "mdi-cctv",
      //   color: "green",
      // },
      {
        id: 5,
        title: {
          ru: "Финансы",
          en: "Finance",
          tr: "Finans",
        },
        icon: "$customCalculator",
        color: "purple",
      },
      {
        id: 6,
        title: {
          ru: "Документы",
          en: "Documents",
          tr: "Belgeler",
        },
        icon: "$customDocumentPdf",
        color: "green",
      },
      {
        id: 7,
        title: {
          ru: "Сообщения",
          en: "Messages",
          tr: "Mesajlar",
        },
        icon: "mdi-message-text-outline",
        color: "purple",
      },
      {
        id: 8,
        title: {
          ru: "Архив",
          en: "Archive",
          tr: "Arşiv",
        },
        icon: "$customArchive",
        color: "green",
      },
    ],
    selectedButton: 1,
  }),
  methods: {
    ...mapActions({
      selectMenuItemId: "house/SELECT_MENU_ITEM_ID",
    }),
    select(num) {
      this.selectedButton = num;
      this.selectMenuItemId(num);
    },
  },
};
</script>
<style scoped>
.selectedButton {
  background-color: white;
}
</style>