<template>
  <v-navigation-drawer
    v-model="drawer"
    permanent
    floating
    color="grey lighten-4"
  >
    <v-row>
      <v-col cols="12" class="text-center">
        <v-icon x-large>$customEl</v-icon>
        <div>
          <span class="purple--text font-weight-bold text-caption"
            >EASY LIFE</span
          >
        </div>
        <div>
          <span class="purple--text font-weight-bold text-caption">HOMES</span>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-center">
        <LanguageSelect :align="align" />
      </v-col>
    </v-row>
  </v-navigation-drawer>
</template>
<script>
import LanguageSelect from "./LanguageSelect.vue";
export default {
  name: "CustomSidebar",
  props: {
    align: {
      type: String,
      default() {
        return "d-flex justify-start align-center";
      },
    },
  },
  components: {
    LanguageSelect,
  },
  data: () => ({
    drawer: true,
  }),
};
</script>