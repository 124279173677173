<template>
  <v-container>
    <v-card
      elevation="0"
      class="rounded-lg"
      height="80vh"
      style="border: 1px solid #a438b6"
      id="messagesTable"
    >
      <v-card-text>
        <v-row>
          <v-col cols="12" md="4" lg="4" xl="4">
            <v-list dense v-if="previewMessages != null">
              <v-list-item-group color="purple">
                <template
                  v-for="(dialog, index) in previewMessages.data.filter(
                    (x) => x.residentialComplex != null
                  )"
                >
                  <v-list-item
                    :key="index + 'dialog'"
                    @click="openDialog(dialog)"
                  >
                    <v-list-item-avatar>
                      <v-img :src="dialog.imageLink"></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content class="text-left">
                      <v-list-item-title>
                        <span class="text-caption" style="font-weight: 600">{{
                          stringValues.apartment[selectedLanguage.code] +
                          " " +
                          dialog.residentialComplex.apartmentNum
                        }}</span>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <span class="text-caption font-weight-medium">{{
                          dialog.msg
                        }}</span></v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider :key="index + 'dialogDvd'"></v-divider>
                </template>
              </v-list-item-group>
            </v-list>
          </v-col>
          <v-divider vertical style="height: 79vh" />
          <v-col cols="12" md="8" lg="8" xl="8">
            <!-- Active Chat -->
            <v-card elevation="0" v-if="selectedDialog != null">
              <!-- User Info  -->
              <v-list-item>
                <v-list-item-avatar>
                  <v-img :src="selectedDialog.imageLink"></v-img>
                </v-list-item-avatar>

                <v-list-item-content class="text-left">
                  <v-list-item-title>
                    <span class="text-subtitle-2" style="font-weight: 600">{{
                      stringValues.apartment[selectedLanguage.code] +
                      " " +
                      selectedDialog.residentialComplex.apartmentNum +
                      " " +
                      selectedDialog.residentialComplex.name
                    }}</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="my-1"></v-divider>
              <!-- Chat  -->
              <v-card
                height="60vh"
                style="overflow-y: auto"
                id="chatHistory"
                class="pr-2 pl-2"
                @scroll.passive="OnScroll"
                fill-height
                elevation="0"
              >
                <v-row v-if="pagingStatus">
                  <v-col cols="12">
                    <v-hover v-slot="{ hover }">
                      <v-alert
                        dense
                        class="purple white--text rounded-lg text-center"
                        tile
                        :elevation="hover ? 8 : 0"
                        @click="getMessagesByPage()"
                      >
                        {{ stringValues.loadMore[selectedLanguage.code] }}
                      </v-alert>
                    </v-hover>
                  </v-col>
                </v-row>
                <v-timeline v-if="chat != null">
                  <v-timeline-item
                    v-for="(msg, index) in chat.data.messages"
                    :key="index"
                    :left="!msg.isFromMe"
                    :right="msg.isFromMe"
                    hide-dot
                  >
                    <v-alert
                      elevation="0"
                      dense
                      class="white--text"
                      :color="!msg.isFromMe ? 'green darken-1' : 'purple'"
                    >
                      <v-row no-gutters>
                        <v-col cols="12" class="text-left">
                          <span class="text-caption"> {{ msg.msg }}</span>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col cols="2" class="text-left" v-if="msg.isFromMe">
                          <span>
                            <v-icon small :color="msg.isRead ? 'blue' : 'white'"
                              >mdi-check-all</v-icon
                            >
                          </span>
                        </v-col>
                        <v-col
                          cols="5"
                          class="text-left"
                          @click="translateMethod(msg.id, msg.msg)"
                          style="cursor: pointer"
                        >
                          <v-icon small color="white"
                            >mdi-google-translate</v-icon
                          >
                          <!-- <span style="font-size: 9px">{{
                            stringValues.showTranslate[selectedLanguage.code]
                          }}</span> -->
                        </v-col>
                        <v-col cols="5" class="text-right">
                          <span style="font-size: 9px" class="grey--text">
                            {{
                              new Date(msg.timeOfMessage).toLocaleTimeString(
                                [],
                                {
                                  month: "2-digit",
                                  day: "2-digit",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                }
                              )
                            }}
                          </span>
                        </v-col>
                      </v-row>
                    </v-alert>
                  </v-timeline-item>
                </v-timeline>
              </v-card>
              <!-- Send Message  -->
              <v-card-actions>
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-text-field
                      append-outer-icon="mdi-send-outline"
                      clearable
                      v-model="outMessage"
                      @keyup.enter="SendMessage()"
                      color="purple"
                      hide-details
                      filled
                      rounded
                      dense
                    >
                      <template v-slot:append-outer>
                        <v-icon
                          color="purple"
                          style="cursor: pointer"
                          @click="SendMessage()"
                          >$customSend</v-icon
                        >
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "MessagesComponent",
  components: {},
  computed: {
    ...mapGetters({
      getPreviewMessages: "chat/GET_PREVIEW_MESSAGES",
      getChat: "chat/GET_CHAT",
      getConnection: "hub/GET_CONNECTION",
      getPagingStatus: "chat/GET_PAGING_STATUS",
      getStringValues: "common/GET_STRING_VALUES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
      getInfo: "house/GET_INFO",
      getSelectedDialog: "chat/GET_SELECTED_DIALOG",
      getTenantsPageStatus: "chat/GET_TENANTS_PAGE_STATUS",
      getTranslate: "translate/GET_INFO",
    }),
    previewMessages: {
      get: function () {
        return this.getPreviewMessages;
      },
      set: function (newVal) {
        this.setPreviewMessages(newVal);
      },
    },
    chat: {
      get: function () {
        return this.getChat;
      },
      set: function (newVal) {
        this.setChat(newVal);
      },
    },
    connection: {
      get: function () {
        return this.getConnection;
      },
    },
    pagingStatus: {
      get: function () {
        return this.getPagingStatus;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
    info: {
      get: function () {
        return this.getInfo;
      },
    },
    selectedDialog: {
      get: function () {
        return this.getSelectedDialog;
      },
      set: function (newVal) {
        this.setSelectedDialog(newVal);
      },
    },
    tenantsPageStatus: {
      get: function () {
        return this.getTenantsPageStatus;
      },
    },
    translate: {
      get: function () {
        return this.getTranslate;
      },
    },
  },
  data: () => ({
    outMessage: null,
    fab: false,
    pageNumber: 1,
  }),
  methods: {
    ...mapActions({
      getPreviewMessagesFromApi: "chat/GET_PREVIEW_MESSAGES_FROM_API",
      getChatFromApi: "chat/GET_CHAT_FROM_API",
      connectToHub: "hub/CONNECT",
      getChatPagingFromApi: "chat/GET_CHAT_PAGING_FROM_API",
      commitSelectedDialog: "chat/COMMIT_SELECTED_DIALOG",
      commitTenantsPageStatus: "chat/COMMIT_TENANTS_PAGE_STATUS",
      commitMenuBtnWidth: "common/COMMIT_MENU_BTN_WIDTH",
      getTranslateFromApi: "translate/GET_FROM_API",
    }),
    ...mapMutations({
      setChat: "chat/SET_CHAT",
      setPreviewMessages: "chat/SET_PREVIEW_MESSAGES",
      setSelectedDialog: "chat/SET_SELECTED_DIALOG",
    }),
    async openDialog(item) {
      this.commitSelectedDialog(item);

      await this.getDialogChat(item);
    },
    async getDialogChat(item) {
      await this.getChatFromApi({
        pageNumber: 1,
        pageSize: 100,
        toUserId: item.companionId,
        rcId: this.info.data.id,
      });
      this.ScrollToEnd();
      this.pageNumber = 1;

      if (
        this.chat.data.messages.filter((x) => !x.isFromMe && !x.isRead).length >
        0
      ) {
        this.connection.invoke("Read", item.companionId, this.info.data.id);
      }
    },
    SendMessage() {
      if (this.outMessage != null && this.outMessage.length > 0) {
        this.connection.invoke(
          "Send",
          this.outMessage,
          this.selectedDialog.companionId,
          this.info.data.id,
          0,
          null
        );

        let timeMessage = new Date();
        let newMessage = {
          isFromMe: true,
          msg: this.outMessage,
          timeOfMessage: timeMessage,
          isRead: false,
        };
        this.chat.data.messages.push(newMessage);

        let message = this.previewMessages.data.find(
          (x) => x.companionId == this.selectedDialog.companionId
        );
        if (message != undefined) {
          message.msg = this.outMessage;
          message.timeOfMessage = timeMessage;
          message.unreadMsgs = ++message.unreadMsgs;
        }
        this.outMessage = "";
        this.ScrollToEnd();
      }
    },
    ReceiveMessage(message, userName) {
      if (this.selectedDialog == null) {
        let messageT = this.previewMessages.data.find(
          (x) => x.name == userName
        );
        if (messageT != undefined) {
          messageT.msg = message.msg;
          messageT.timeOfMessage = new Date();
          messageT.unreadMsgs = ++messageT.unreadMsgs;
        }
        return;
      }

      if (this.selectedDialog.name == userName) {
        let timeMessage = new Date();
        let newMessage = {
          isFromMe: false,
          msg: message.msg,
          timeOfMessage: timeMessage,
        };

        this.chat.data.messages.push(newMessage);
        let messageT = this.previewMessages.data.find(
          (x) => x.companionId == this.selectedDialog.companionId
        );
        if (messageT != undefined) {
          messageT.msg = message.msg;
          messageT.timeOfMessage = timeMessage;
          messageT.unreadMsgs = ++messageT.unreadMsgs;
        }
        this.ScrollToEnd();

        this.connection.invoke(
          "Read",
          this.selectedDialog.companionId,
          this.info.data.id
        );
      } else {
        let messageT = this.previewMessages.data.find(
          (x) => x.name == userName
        );
        if (messageT != undefined) {
          messageT.msg = message.msg;
          messageT.timeOfMessage = new Date();
          messageT.unreadMsgs = ++messageT.unreadMsgs;
        }
      }
    },
    ReceiveReadMessage(userName) {
      if (this.selectedDialog != null) {
        if (this.selectedDialog.name == userName) {
          this.chat.data.messages
            .filter((x) => x.isFromMe && !x.isRead)
            .forEach((x) => (x.isRead = true));
        }
      }
    },
    OnScroll() {
      var container = this.$el.querySelector("#chatHistory");
      this.fab = container.scrollHeight - container.scrollTop > 500;
    },

    ScrollToEnd() {
      setTimeout(() => {
        var container = this.$el.querySelector("#chatHistory");
        container.scrollTop = container.scrollHeight;
      });
    },
    async getMessagesByPage() {
      await this.getChatPagingFromApi({
        pageNumber: ++this.pageNumber,
        pageSize: 100,
        toUserId: this.selectedDialog.companionId,
        rcId: this.info.data.id,
      });
    },
    initMenuBtnWidth() {
      var btnOffsets = document
        .getElementById("menuItem_" + 7)
        .getBoundingClientRect();
      var tableOffsets = document
        .getElementById("messagesTable")
        .getBoundingClientRect();
      let width = tableOffsets.left - btnOffsets.right + btnOffsets.width + 2;
      this.commitMenuBtnWidth(width);
    },
    async translateMethod(id, msg) {
      await this.getTranslateFromApi({
        text: msg,
        lang: this.selectedLanguage.code,
      });

      if (this.translate.succeeded) {
        this.chat.data.messages.find((x) => x.id == id).msg =
          this.translate.data;
      }
    },
  },
  async mounted() {
    this.initMenuBtnWidth();
    window.addEventListener("resize", this.initMenuBtnWidth);
    await this.getPreviewMessagesFromApi({
      pageNumber: 1,
      pageSize: 50,
      residentialComplexId: this.info.data.id,
    });

    if (this.tenantsPageStatus) {
      await this.getDialogChat(this.selectedDialog);

      this.commitTenantsPageStatus(false);

      var isExistsDialog = this.previewMessages.data.find(
        (x) => x.companionId == this.selectedDialog.companionId
      );

      if (isExistsDialog == undefined || isExistsDialog == null) {
        this.previewMessages.data.unshift({
          companionId: this.selectedDialog.companionId,
          imageLink: this.selectedDialog.imageLink,
          isFromMe: true,
          msg: "",
          name: this.selectedDialog.name,
          rcId: this.selectedDialog.rcId,
          residentialComplex: this.selectedDialog.residentialComplex,
          timeOfMessage: new Date(),
          unreadMsgs: 0,
        });
      }
    }

    this.connectToHub("https://housechat.el-turk.com/chat");
    this.connection.on("Receive", this.ReceiveMessage);
    this.connection.on("ReceiveRead", this.ReceiveReadMessage);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.initMenuBtnWidth);
  },
  destroyed: function () {
    this.selectedDialog = null;
  },
};
</script>
<style>
.v-timeline {
  height: 0;
}
.v-timeline::before {
  height: 0;
}
</style>
<style scoped>
.v-text-field--outline .v-input__slot {
  border-color: red;
}
</style>