<template>
  <v-app>
    <v-main class="grey lighten-4">
      <router-view></router-view>
      <v-snackbar
        v-model="snackbar.show"
        :timeout="snackbar.timeout"
        bottom
        :color="snackbar.color"
      >
        {{ snackbar.text }}
      </v-snackbar>
      <v-dialog
        v-model="dialog.show"
        :fullscreen="dialog.fullscreen"
        :hide-overlay="dialog.hide_overlay"
        :transition="dialog.transition"
        :max-width="dialog.width"
        persistent
        scrollable
      >
        <v-card>
          <v-card-title class="pa-0">
            <v-toolbar dark color="grey">
              <v-btn icon dark @click="dialog.show = false" color="white">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>{{ dialog.title }}</v-toolbar-title>
            </v-toolbar>
          </v-card-title>
          <v-card-text class="px-3">
            <v-row class="grey lighten-4">
              <v-col cols="12">
                <ResidentialComplexParametersEditComponent
                  v-if="dialog.view_id == 1"
                />
                <ResidentialComplexImagesEditComponent
                  v-else-if="dialog.view_id == 2"
                />
                <div v-else></div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import stringValuesData from "../src/assets/string_values.json";
import ResidentialComplexParametersEditComponent from "./components/ResidentialComplex/ResidentialComplexParametersEditComponent.vue";
import ResidentialComplexImagesEditComponent from "./components/ResidentialComplex/ResidentialComplexImagesEditComponent.vue";
export default {
  name: "App",
  components: {
    ResidentialComplexParametersEditComponent,
    ResidentialComplexImagesEditComponent,
  },
  computed: {
    ...mapGetters({
      getSnackbar: "common/GET_SNACKBAR",
      getLocation: "common/GET_LOCATION",
      getDialog: "common/GET_DIALOG",
    }),
    snackbar: {
      get: function () {
        return this.getSnackbar;
      },
    },
    location: {
      get: function () {
        return this.getLocation;
      },
      set: function (newVal) {
        this.setLocation(newVal);
      },
    },
    dialog: {
      get: function () {
        return this.getDialog;
      },
    },
  },
  data: () => ({
    stringValuesComp: stringValuesData,
  }),
  methods: {
    ...mapActions({
      commitStringValues: "common/COMMIT_STRING_VALUES",
      generateSnackbar: "common/GENERATE_SNACKBAR",
      generateLocation: "common/GENERATE_LOCATION",
    }),
    ...mapMutations({
      setLocation: "common/SET_LOCATION",
    }),
    async getLocationMethod() {
      if (navigator.geolocation) {
        try {
          const getCoords = async () => {
            const pos = await new Promise((resolve, reject) => {
              navigator.geolocation.getCurrentPosition(resolve, reject);
            });
            return {
              longitude: pos.coords.longitude,
              latitude: pos.coords.latitude,
            };
          };
          this.generateLocation(await getCoords());
        } catch {
          this.generateLocation({
            longitude: 0,
            latitude: 0,
          });
        }
      } else {
        this.generateLocation({
          longitude: 0,
          latitude: 0,
        });
      }
    },
  },
  async created() {
    await this.getLocationMethod();
    this.commitStringValues(this.stringValuesComp);
  },
};
</script>
