import collector from '../api/collector/index'

const UpdatePointById = (query) => {
    return new Promise((resolve) => {
        resolve(collector.collector.UpdatePointById(query))
    })
}

const GetParameters = () => {
    return new Promise((resolve) => {
        resolve(collector.collector.GetParameters())
    })
}

export default {
    namespaced: true,
    state: {
        parameters: {
            succeeded: false
        },
        actionStatus: {
            succeeded: false
        },
    },
    getters: {
        GET_ACTION_STATUS(state) {
            return state.actionStatus;
        },
        GET_PARAMETERS(state) {
            return state.parameters;
        },
    },
    mutations: {
        SET_ACTION_STATUS(state, payload) {
            state.actionStatus = payload;
        },
        SET_PARAMETERS(state, payload) {
            state.parameters = payload;
        },
    },
    actions: {
        async UPDATE_FROM_API({ commit }, payload) {
            await UpdatePointById(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        commit('SET_ACTION_STATUS', { succeeded: true, data: data });
                    }
                    else {
                        commit('SET_ACTION_STATUS', { succeeded: false, data: data });
                    }
                }
            )
                .catch(() => {
                    commit('SET_ACTION_STATUS', { succeeded: false });
                })
        },
        async GET_PARAMETERS_FROM_API({ commit }, payload) {
            await GetParameters(payload).then(
                ({
                    data, status
                }) => {
                    if (status === 200) {
                        if (data.succeeded) {
                            let dataObj = {
                                data: data.data.find(x => x.id == 1).parameters,
                                message: data.message,
                                statusCode: data.statusCode,
                                succeeded: data.succeeded
                            };
                            commit('SET_PARAMETERS', dataObj);
                        }
                        else {
                            commit('SET_PARAMETERS', data);
                        }
                    }
                }
            )
                .catch(() => {
                    commit('SET_PARAMETERS', { succeeded: false });
                })
        },
    }
}