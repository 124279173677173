import VueRouter from 'vue-router'

import Login from "../views/Account/Login"
import Main from "../views/Main/Main"

var router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/account/login',
            component: Login
        },
        {
            path: '/',
            component: Main
        },
    ]
})

export default router