<template>
  <v-container>
    <v-row
      style="height: 60vh"
      class="d-flex justify-center overflow-y-auto overflow-x-hidden"
    >
      <v-col cols="6">
        <!-- Residential complex -->
        <v-row>
          <v-col cols="12" class="text-center">
            <div>
              <span class="text-caption font-weight-medium grey--text">{{
                stringValues.residentialComplex[selectedLanguage.code]
              }}</span>
            </div>
            <div>
              <span class="font-weight-bold purple--text">{{
                apartment.data.residentialComplex.name
              }}</span>
            </div>
          </v-col>
        </v-row>
        <!-- Residential complex end -->

        <!-- Status -->
        <v-row class="d-flex align-center">
          <v-col cols="2" class="text-left">
            <span class="font-weight-bold purple--text">{{
              stringValues.status[selectedLanguage.code]
            }}</span>
          </v-col>
          <v-col cols="10" class="text-left">
            <v-btn-toggle
              v-model="apartment.data.applianceStatusId"
              color="green"
              group
              class="rounded-lg"
            >
              <v-btn
                v-for="item in apartment.data.applianceStatuses"
                :key="item.id"
                small
                :value="item.id"
                class="text-capitalize grey lighten-4 rounded-lg"
                @click="updateStatus(item.id)"
              >
                {{ item.name }}
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
        <!-- Status end -->

        <!-- Owner -->
        <v-row
          class="mt-6"
          v-if="
            apartment.data.users.filter((x) => x.tenantTypeId == 1).length > 0
          "
        >
          <v-col cols="12">
            <v-row>
              <v-col cols="6" class="text-left">
                <span class="text-caption font-weight-medium grey--text">{{
                  stringValues.owner[selectedLanguage.code]
                }}</span>
              </v-col>
              <v-col cols="6" class="text-right">
                <span
                  class="text-caption"
                  style="font-weight: 600 !important"
                  >{{
                    apartment.data.users.find((x) => x.tenantTypeId == 1)
                      .lastName == null
                      ? apartment.data.users.find((x) => x.tenantTypeId == 1)
                          .tapuName
                      : apartment.data.users.find((x) => x.tenantTypeId == 1)
                          .lastName
                  }}</span
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="text-left">
                <span class="text-caption font-weight-medium grey--text">{{
                  stringValues.telephone[selectedLanguage.code]
                }}</span>
              </v-col>
              <v-col cols="6" class="text-right">
                <span
                  class="text-caption"
                  style="font-weight: 600 !important"
                  >{{
                    apartment.data.users.find((x) => x.tenantTypeId == 1)
                      .telephoneNumber
                  }}</span
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="text-left">
                <span class="text-caption font-weight-medium grey--text"
                  >E-mail</span
                >
              </v-col>
              <v-col cols="6" class="text-right">
                <span
                  class="text-caption"
                  style="font-weight: 600 !important"
                  >{{
                    apartment.data.users.find((x) => x.tenantTypeId == 1).email
                  }}</span
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="text-left">
                <span class="text-caption font-weight-medium grey--text"
                  >WhatsApp/Telegram</span
                >
              </v-col>
              <v-col cols="6" class="text-right">
                <span
                  class="text-caption"
                  style="font-weight: 600 !important"
                  >{{
                    apartment.data.users.find((x) => x.tenantTypeId == 1)
                      .telephoneNumber
                  }}</span
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- Owner end -->

        <!-- Guests -->
        <v-row
          v-for="(item, index) in apartment.data.users.filter(
            (x) => x.tenantTypeId != 1
          )"
          :key="item.userId"
          class="mt-8"
        >
          <v-col cols="12">
            <v-row>
              <v-col cols="6" class="text-left">
                <span class="text-caption font-weight-medium grey--text"
                  >{{ stringValues.guest[selectedLanguage.code] }} №{{
                    ++index
                  }}</span
                >
              </v-col>
              <v-col cols="6" class="text-right">
                <span
                  class="text-caption"
                  style="font-weight: 600 !important"
                  >{{
                    item.lastName == null ? item.tapuName : item.lastName
                  }}</span
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="text-left">
                <span class="text-caption font-weight-medium grey--text">{{
                  stringValues.telephone[selectedLanguage.code]
                }}</span>
              </v-col>
              <v-col cols="6" class="text-right">
                <span
                  class="text-caption"
                  style="font-weight: 600 !important"
                  >{{ item.telephoneNumber }}</span
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="text-caption text-left">
                <span class="font-weight-medium grey--text">E-mail</span>
              </v-col>
              <v-col cols="6" class="text-right">
                <span
                  class="text-caption"
                  style="font-weight: 600 !important"
                  >{{ item.email }}</span
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="text-caption text-left">
                <span class="font-weight-medium grey--text"
                  >WhatsApp/Telegram</span
                >
              </v-col>
              <v-col cols="6" class="text-right">
                <span
                  class="text-caption"
                  style="font-weight: 600 !important"
                  >{{ item.telephoneNumber }}</span
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- Guests end -->
      </v-col>

      <v-col cols="4">
        <!-- Apartment num -->
        <v-row>
          <v-col cols="6">
            <div>
              <span class="text-caption font-weight-medium grey--text">{{
                stringValues.apartment[selectedLanguage.code]
              }}</span>
            </div>
            <div>
              <span class="font-weight-bold black--text">{{
                apartment.data.number
              }}</span>
            </div>
          </v-col>
          <v-col cols="6">
            <div>
              <span class="text-caption font-weight-medium grey--text">{{
                stringValues.block[selectedLanguage.code]
              }}</span>
            </div>
            <div>
              <span class="font-weight-bold black--text">{{
                apartment.data.bloc
              }}</span>
            </div>
          </v-col>
        </v-row>
        <!-- Apartment num end -->

        <v-row class="mt-6">
          <v-col cols="12">
            <v-card
              elevation="0"
              color="grey lighten-4"
              class="rounded-lg px-4 py-4"
            >
              <v-row class="d-flex align-center">
                <v-col cols="6" class="text-left">
                  <span class="text-caption font-weight-medium grey--text">{{
                    stringValues.debt[selectedLanguage.code]
                  }}</span>
                </v-col>
                <v-col cols="3" class="text-right">
                  <span class="text-subtitle-2 font-weight-bold"
                    >{{ apartment.data.debt }} TL</span
                  >
                </v-col>
                <v-col cols="3" class="text-right">
                  <v-btn fab x-small text @click="dialog = true">
                    <v-icon small>$customEdit3</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="mt-6">
          <v-col cols="12">
            <v-card
              elevation="0"
              color="grey lighten-4"
              class="rounded-lg px-4 py-4"
            >
              <v-row no-gutters class="d-flex align-center">
                <v-col cols="6" class="text-left">
                  <span class="text-caption font-weight-medium grey--text">{{
                    stringValues.notes[selectedLanguage.code]
                  }}</span>
                </v-col>
                <v-col cols="3" class="text-right"> </v-col>
                <v-col cols="3" class="text-right">
                  <v-btn fab x-small text @click="dialogNote = true">
                    <v-icon small>$customEdit3</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row no-gutters class="d-flex align-center">
                <v-col cols="12" class="text-left">
                  <span class="text-caption font-weight-medium black--text">{{
                    apartment.data.note != null ? apartment.data.note : ""
                  }}</span>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <!-- documents -->
        <v-row>
          <v-col
            cols="6"
            v-for="item in apartment.data.userResidentialComplexAttachments"
            :key="item.id"
            class="d-flex justify-center align-center"
          >
            <v-btn x-small text fab @click="openDocument(item.link)">
              <v-icon small> $customDocumentPdf2</v-icon>
            </v-btn>

            <span
              class="text-caption font-weight-medium d-inline-block text-truncate text-decoration-underline mx-1"
              style="width: 100%; cursor: pointer"
              @click="openDocument(item.link)"
              >{{ item.name }}</span
            >

            <v-btn
              x-small
              text
              fab
              @click="
                deleteItemId = item.id;
                dialogDelete = true;
              "
            >
              <v-icon small>$customTrash2</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- documents end -->
    <!-- buttons -->
    <v-row class="d-flex justify-center">
      <v-col cols="5">
        <v-btn
          elevation="0"
          class="mx-2 purple white--text text-capitalize text-caption rounded-lg"
          width="100%"
          @click="dialogAdd = true"
        >
          <v-icon small left>$customDocument</v-icon>
          {{ stringValues.addDocument[selectedLanguage.code] }}
        </v-btn>
      </v-col>
      <v-col cols="5">
        <v-btn
          elevation="0"
          class="mx-2 orange white--text text-capitalize text-caption rounded-lg"
          width="100%"
          @click="save()"
          >{{ stringValues.updateDocuments[selectedLanguage.code] }}</v-btn
        >
      </v-col>
    </v-row>
    <!-- buttons end -->

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-subtitle-2">{{
            stringValues.change[selectedLanguage.code]
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="apartment.data.debt"
                  outlined
                  hide-details
                  color="purple"
                  dense
                  class="custom-text-field"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="purple" small text @click="dialog = false">
            {{ stringValues.cancel[selectedLanguage.code] }}
          </v-btn>
          <v-btn color="purple" small text @click="updateDebt">
            {{ stringValues.apply[selectedLanguage.code] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogNote" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-subtitle-2">{{
            stringValues.change[selectedLanguage.code]
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="apartment.data.note"
                  color="purple"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="purple" small text @click="dialogNote = false">
            {{ stringValues.cancel[selectedLanguage.code] }}
          </v-btn>
          <v-btn color="purple" small text @click="updateNote">
            {{ stringValues.apply[selectedLanguage.code] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAdd" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-subtitle-2">{{
            stringValues.add[selectedLanguage.code]
          }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  v-model="documentName"
                  :label="stringValues.name1[selectedLanguage.code]"
                  color="purple"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-file-input
                  v-model="documentFile"
                  accept="image/png, image/jpeg, image/bmp, image/jpg, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  placeholder="Select files"
                  prepend-icon="mdi-file-arrow-up-down"
                  :label="stringValues.addFile[selectedLanguage.code]"
                  color="purple"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="purple" text small @click="dialogAdd = false">
            {{ stringValues.cancel[selectedLanguage.code] }}
          </v-btn>
          <v-btn color="purple" text small @click="addDocument()">
            {{ stringValues.apply[selectedLanguage.code] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-subtitle-2">{{
          stringValues.deleteSure[selectedLanguage.code]
        }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="purple" text small @click="dialogDelete = false">
            {{ stringValues.cancel[selectedLanguage.code] }}</v-btn
          >
          <v-btn color="purple" text small @click="deleteDocument()">{{
            stringValues.apply[selectedLanguage.code]
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "ApartmentCard",
  props: {
    number: {
      type: String,
      default() {
        return null;
      },
    },
    bloc: {
      type: String,
      default() {
        return null;
      },
    },
  },
  computed: {
    ...mapGetters({
      getLanguages: "common/GET_LANGUAGES",
      getSelectedLanguage: "common/GET_SELECTED_LANGUAGE",
      getStringValues: "common/GET_STRING_VALUES",
      getInfo: "house/GET_INFO",
      getApartment: "house/GET_APARTMENT",
      getActionStatus: "house/GET_ACTION_STATUS",
      getApplianceStatuses: "house/GET_APPLIANCE_STATUSES",
      getApplianceList: "house/GET_APPLIANCES",
      getTenantsList: "house/GET_TENANTS",
    }),
    languages: {
      get: function () {
        return this.getLanguages;
      },
    },
    stringValues: {
      get: function () {
        return this.getStringValues;
      },
    },
    selectedLanguage: {
      get: function () {
        return this.getSelectedLanguage;
      },
    },
    info: {
      get: function () {
        return this.getInfo;
      },
    },
    apartment: {
      get: function () {
        return this.getApartment;
      },
    },
    actionStatus: {
      get: function () {
        return this.getActionStatus;
      },
    },
    applianceStatuses: {
      get: function () {
        return this.getApplianceStatuses;
      },
    },
    applianceList: {
      get: function () {
        return this.getApplianceList;
      },
      set: function (newVal) {
        this.setApplianceList(newVal);
      },
    },
    tenantsList: {
      get: function () {
        return this.getTenantsList;
      },
      set: function (newVal) {
        this.setTenantsList(newVal);
      },
    },
  },
  data: () => ({
    dialog: false,
    dialogNote: false,
    dialogAdd: false,
    documentFile: null,
    documentName: null,
    dialogDelete: false,
    deleteItemId: null,
  }),
  methods: {
    ...mapActions({
      getApartmentFromApi: "house/GET_APARTMENT_FROM_API",
      updateNoteFromApi: "house/UPDATE_APARTMENT_NOTE_FROM_API",
      updateDebtFromApi: "house/UPDATE_APARTMENT_DEBT_FROM_API",
      addAttachmentFromApi: "house/ADD_APARTMENT_ATTACHMENT_FROM_API",
      deleteFromApi: "house/DELETE_APARTMENT_ATTACHMENT_FROM_API",
      updateStatusFromApi: "house/UPDATE_APARTMENT_STATUS_FROM_API",
    }),
    ...mapMutations({
      setSnackBar: "common/SET_SNACKBAR",
      setApplianceList: "house/SET_APPLIANCES",
      setTenantsList: "house/SET_TENANTS",
    }),
    async updateDebt() {
      await this.updateDebtFromApi({
        residentialComplexId: this.info.data.id,
        apartmentNum: this.number,
        amount: this.apartment.data.debt,
        bloc: this.bloc,
      });
      if (this.actionStatus.succeeded) {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "green",
          text: this.stringValues.ok[this.selectedLanguage.code],
        });
      } else {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "purple",
          text: this.stringValues.error[this.selectedLanguage.code],
        });
      }
      this.dialog = false;
    },
    async updateNote() {
      await this.updateNoteFromApi({
        residentialComplexId: this.info.data.id,
        apartmentNum: this.number,
        note: this.apartment.data.note,
        bloc: this.bloc,
      });
      if (this.actionStatus.succeeded) {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "green",
          text: this.stringValues.ok[this.selectedLanguage.code],
        });
      } else {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "purple",
          text: this.stringValues.error[this.selectedLanguage.code],
        });
      }
      this.dialogNote = false;
    },
    async addDocument() {
      if (this.documentFile != null && this.documentFile != undefined) {
        var base64 = await this.documentToBase64();
        base64 = base64.substring(base64.indexOf(",") + 1);

        var extensions = /[.]/.exec(this.documentFile.name)
          ? /[^.]+$/.exec(this.documentFile.name)
          : undefined;
        if (
          extensions != undefined &&
          extensions != null &&
          extensions.length > 0
        ) {
          await this.addAttachmentFromApi({
            apartmentNum: this.number,
            bloc: this.bloc,
            residentialComplexId: this.info.data.id,
            attachments: [
              {
                name: this.documentName,
                value: base64,
                extension: "." + extensions[0],
              },
            ],
          });
          if (this.actionStatus.succeeded) {
            this.apartment.data.userResidentialComplexAttachments.push({
              apartmentNum: this.number,
              id: 0,
              link: null,
              name: this.documentName,
              rcId: this.info.data.id,
            });
            this.setSnackBar({
              show: true,
              timeout: 3000,
              color: "green",
              text: this.stringValues.ok[this.selectedLanguage.code],
            });
          } else {
            this.setSnackBar({
              show: true,
              timeout: 3000,
              color: "purple",
              text: this.stringValues.error[this.selectedLanguage.code],
            });
          }
          this.dialogAdd = false;
        }
      }
    },
    async documentToBase64() {
      const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });
      };

      return await getBase64(this.documentFile);
    },
    uidv4() {
      return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
    },
    openDocument(link) {
      window.open(link, "_blank");
    },
    async deleteDocument() {
      await this.deleteFromApi({
        attachmentId: this.deleteItemId,
      });
      if (this.actionStatus.succeeded) {
        this.apartment.data.userResidentialComplexAttachments =
          this.apartment.data.userResidentialComplexAttachments.filter(
            (x) => x.id != this.deleteItemId
          );
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "green",
          text: this.stringValues.ok[this.selectedLanguage.code],
        });
      } else {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "purple",
          text: this.stringValues.error[this.selectedLanguage.code],
        });
      }
      this.dialogDelete = false;
    },
    async save() {
      await this.getApartmentFromApi({
        residentialComplexId: this.info.data.id,
        apartmentNum: this.number,
        bloc: this.bloc,
      });
    },
    async updateStatus(statusId) {
      await this.updateStatusFromApi({
        residentialComplexId: this.info.data.id,
        apartmentNum: this.number,
        statusId: statusId,
        bloc: this.bloc,
      });
      if (this.actionStatus.succeeded) {
        if (this.applianceList.totalRecords > 0) {
          this.applianceList.data
            .filter((x) => x.apartmentNum == this.number)
            .forEach((x) => {
              x.applianceStatusId = statusId;
              x.applianceStatus = this.applianceStatuses.data.find(
                (x) => x.id == statusId
              );
            });
        }
        if (this.tenantsList.totalRecords > 0) {
          this.tenantsList.data
            .filter((x) => x.apartmentNum == this.number)
            .forEach((x) => {
              x.applianceStatusId = statusId;
              x.applianceStatus = this.applianceStatuses.data.find(
                (x) => x.id == statusId
              );
            });

          this.tenantsList.data = this.tenantsList.data.filter(
            (x) => x.applianceStatusId == 2
          );
        }
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "green",
          text: this.stringValues.ok[this.selectedLanguage.code],
        });
      } else {
        this.setSnackBar({
          show: true,
          timeout: 3000,
          color: "purple",
          text: this.stringValues.error[this.selectedLanguage.code],
        });
      }
    },
  },
  async created() {
    await this.getApartmentFromApi({
      residentialComplexId: this.info.data.id,
      apartmentNum: this.number,
      bloc: this.bloc,
    });
  },
};
</script>